import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ListPerformanceChart from './ListPerformanceChart';
import Lists from './Lists';
import styles from './Dashboard.module.scss'
import ListDetails from "./ListDetails";
import ListChanges from './ListChanges';

export const Dashboard: FC = observer(function () {

    return (
        <div className={styles.container}>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={5} lg={4}>
                            <Paper  className={styles.paperSection} sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                                <Lists/>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={8}>
                            <Paper className={styles.paperSection}
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <ListPerformanceChart/>
                            </Paper>

                            <Paper className={styles.paperSection}
                                   sx={{
                                       p: 2,
                                       display: 'flex',
                                       flexDirection: 'column',
                                       maxHeight: '60vh',
                                   }}
                            >
                                <ListDetails/>
                            </Paper>
                            
                            <Paper className={styles.paperSection}
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <ListChanges/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
        </div>
    );
});
