import {FC} from "react";
import {AddressComponent} from "./AddressComponent";

export const Account: FC = () =>{
    return <>
        <h3>Account</h3>

                <h4>Account Postal Address</h4>
                <AddressComponent />

    </>
    
    
    
}