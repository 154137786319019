import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTheme} from '@mui/material/styles';
import {CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Title from '../Title';
import {observer} from 'mobx-react-lite';
import {useStore} from "../../hooks";
import styles from './ListPerformanceChart.module.scss';
import {IListPerformanceModel} from "../../store/ListsModel";
import {Skeleton} from "@mui/material";
import {TvChartComponent} from "../TvChartComponent/TvChartComponent";
import moment from "moment";

export default observer(function Chart() {
    const theme = useTheme();
    const {listsStore} = useStore();
    const [data, setData] = useState<[{ time: string, value: number }?]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    

    useEffect(() => {
        if (!listsStore.selectedList) return;

        setIsLoading(true);
        listsStore.loadListPerformance(listsStore.selectedList.name).then((result: IListPerformanceModel) => {
            if (result.data && result.data.length > 0) {
                let tmp: [{ time: string, value: number }?] = [];
                let accumulateChange = 0;

                tmp.push({time: moment(result.data[0].date, 'DD-MM-YYYY').subtract(1, 'days').format('YYYY-MM-DD'), value: 0});

                result.data.forEach((d) => {
                    accumulateChange = accumulateChange + d.changeBps;
                    let formattedDate = moment(d.date, 'DD-MM-YYYY').format('YYYY-MM-DD');
                    
                    tmp.push({time: formattedDate, value: accumulateChange/100});
                });

                setData(tmp);

            } else {
                setData([]);
            }
        }).finally(() => {
            setIsLoading(false);
        });

    }, [listsStore, listsStore.selectedList]);
    
    return (
        <div className={styles.container}>
            <Title>{listsStore.selectedList?.name} - Performance</Title>

            {!isLoading && data &&  (
                <TvChartComponent data={data}></TvChartComponent>
            )}

            {isLoading && (
                <Skeleton variant={"rectangular"} height={690}></Skeleton>
            )}
        </div>
    );
});