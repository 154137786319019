import * as React from 'react';
import {useEffect, useState} from 'react';
import styles from './UserHome.module.scss';
import Box from "@mui/material/Box";
import {observer} from "mobx-react-lite";
import {useStore} from "../../hooks";
import {Holdings} from "../portfolio/Holdings";
import {Rebalancer} from "../portfolio/Rebalancer";
import {Dashboard} from "../dashboard/Dashboard";
import {Portfolio} from "../portfolio/Portfolio";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {AdminDashboard} from "../adminDashboard/AdminDashboard";
import {SubscriptionChanges} from "../subscriptionUpdates/SubscriptionChanges";

export const UserHome = () => {

    const {accountStore} = useStore();
    const [tabValue, setTabValue] = React.useState(0);
    const [, setIsLoading] = useState<boolean>(true);
    const [isAdmin , setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);

        accountStore.loadAccount().then(() => {
            setIsLoading(false);
        });
        
        accountStore.isAdmin().then((isAdmin) => {
            if (isAdmin) {
                setIsAdmin(true);
            }
        });
    }, [accountStore]);

    const handleTabChange = (event: any, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <div className={styles.container}>
                    <SubscriptionChanges tabValue={tabValue} onTabValueChange={(v : number) => setTabValue(v)}/>
            
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}} className={styles.navBox}>
                        <Tabs value={tabValue} onChange={handleTabChange} centered={true} variant={"scrollable"} scrollButtons={"on"} color={"primary"} indicatorColor={"primary"} >
                            <Tab label="Lists" {...a11yProps(0)} value={0} />
                            <Tab label="AUS Subscription" {...a11yProps(1)} value={1} />
                            <Tab label="US Subscription" {...a11yProps(1.1)} value={1.1} />

                            {accountStore.account?.isSection708Investor && (
                                <Tab label="Holdings" {...a11yProps(2)} value={2} />
                            )}
                            {accountStore.account?.isSection708Investor && (
                                <Tab label="Rebalance" {...a11yProps(3)} value={3} />
                            )}
                            {isAdmin && (
                                <Tab label="Admin" {...a11yProps(4)} value={4} />
                            )}
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <Dashboard/>
                    </TabPanel>
                    <TabPanel index={tabValue} value={1}>
                        <Portfolio isUS={false}/>
                    </TabPanel>
                    <TabPanel index={tabValue} value={1.1}>
                        <Portfolio isUS={true}/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <Holdings/>
                    </TabPanel>
                    <TabPanel index={tabValue} value={3}>
                        <Rebalancer/>
                    </TabPanel>
                    <TabPanel index={tabValue} value={4}>
                        <AdminDashboard/>
                    </TabPanel>
        </div>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = observer((props: TabPanelProps) => {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
});

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}