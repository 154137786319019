import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {App} from "./App";
import {IAuthenticationProvider} from "./auth/AuthenticationTypes";
import {AuthenticationProvider} from "./auth/AuthenticationProvider";
import {AUTHORITY, CLIENT_ID, TENANT_ID} from "./appSettings";
import {createBrowserHistory} from "history";
import {getDefaultStore} from "./store/RootStoreModel";

export const auth: IAuthenticationProvider = new AuthenticationProvider(
    {
        auth: {
            clientId: CLIENT_ID,
            authority: AUTHORITY,
            knownAuthorities: [TENANT_ID],
            validateAuthority: true,
            redirectUri: window.location.origin + '/signedin',
            postLogoutRedirectUri: window.location.origin,
        }
    },
    {
        scopes: ['openid', 'profile'],
    }
);

const history = createBrowserHistory();
const store = getDefaultStore(auth);


ReactDOM.render(
    <App history={history} store={store} auth={auth} />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
