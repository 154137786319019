import * as React from 'react';
import {useEffect, useState} from 'react';
import {useStore} from "../../hooks";
import Title from "../Title";
import styles from './ListChanges.module.scss';
import {observer} from "mobx-react-lite";
import {IListChangeModel} from "../../store/ListsModel";
import moment from "moment";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {Skeleton} from "@mui/material";

export default observer(function ListDetails() {
    const {listsStore} = useStore();
    const [changes, setChanges] = useState<IListChangeModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!listsStore.selectedList) return;
        setIsLoading(true);
        listsStore.loadListChanges(listsStore.selectedList.name).then((changes) => {
            setChanges(changes)
        }).finally(() => {
            setIsLoading(false);
        });
    }, [listsStore, listsStore.selectedList]);

    return (
        <div className={styles.container}>

            {isLoading ? (
                <>
                    <Skeleton variant={"rectangular"} height={200}/>
                </>) : (
                <>
                    <Title>{listsStore.selectedList?.name} - Changes</Title>

                    {changes?.changes?.length === 0 && (
                        <p>No changes in the last 10 days.</p>
                    )}
                    <div className={styles.tableContainer}>
                        <ul>
                            {changes?.changes.map((change, key) => (
                                <li key={key}>{
                                    moment(change.date).format('l')}&nbsp; {change.changeType === 'Changed' ? 'Weighting changed' : change.changeType}&nbsp;
                                    {change.ticker} &nbsp;
                                    {(change.changeBps / 100).toFixed(2)}%&nbsp;
                                    {change.changeBps > 0 ? <ArrowUpwardIcon className={styles.green}/> :
                                        <ArrowDownwardIcon className={styles.red}/>}
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </div>

    );
})
