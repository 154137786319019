import {FC} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

interface ICountryComponentProps{
    required?: boolean;
    label: string
}

export const CountryComponent: FC<ICountryComponentProps> = (({required, label}: ICountryComponentProps) => {
    return <>
        <FormControl sx={{minWidth: 150}} required>
            <InputLabel id="country-label">{label}</InputLabel>
            <Select
                labelId="country-label"
                id={label+ "-select"}
                value={''}
                label={label}
                required={!!required}
                onChange={() => {
                }}
            >
                <MenuItem value="AFG">AFG</MenuItem>
                <MenuItem value="ALB">ALB</MenuItem>
                <MenuItem value="DZA">DZA</MenuItem>
                <MenuItem value="ASM">ASM</MenuItem>
                <MenuItem value="AND">AND</MenuItem>
                <MenuItem value="AGO">AGO</MenuItem>
                <MenuItem value="AIA">AIA</MenuItem>
                <MenuItem value="ATA">ATA</MenuItem>
                <MenuItem value="ATG">ATG</MenuItem>
                <MenuItem value="ARG">ARG</MenuItem>
                <MenuItem value="ARM">ARM</MenuItem>
                <MenuItem value="ABW">ABW</MenuItem>
                <MenuItem value="AUS">AUS</MenuItem>
                <MenuItem value="AUT">AUT</MenuItem>
                <MenuItem value="AZE">AZE</MenuItem>
                <MenuItem value="BHS">BHS</MenuItem>
                <MenuItem value="BHR">BHR</MenuItem>
                <MenuItem value="BGD">BGD</MenuItem>
                <MenuItem value="BRB">BRB</MenuItem>
                <MenuItem value="BLR">BLR</MenuItem>
                <MenuItem value="BEL">BEL</MenuItem>
                <MenuItem value="BLZ">BLZ</MenuItem>
                <MenuItem value="BEN">BEN</MenuItem>
                <MenuItem value="BMU">BMU</MenuItem>
                <MenuItem value="BTN">BTN</MenuItem>
                <MenuItem value="BOL">BOL</MenuItem>
                <MenuItem value="BES">BES</MenuItem>
                <MenuItem value="BIH">BIH</MenuItem>
                <MenuItem value="BWA">BWA</MenuItem>
                <MenuItem value="BVT">BVT</MenuItem>
                <MenuItem value="BRA">BRA</MenuItem>
                <MenuItem value="IOT">IOT</MenuItem>
                <MenuItem value="BRN">BRN</MenuItem>
                <MenuItem value="BGR">BGR</MenuItem>
                <MenuItem value="BFA">BFA</MenuItem>
                <MenuItem value="BDI">BDI</MenuItem>
                <MenuItem value="CPV">CPV</MenuItem>
                <MenuItem value="KHM">KHM</MenuItem>
                <MenuItem value="CMR">CMR</MenuItem>
                <MenuItem value="CAN">CAN</MenuItem>
                <MenuItem value="CYM">CYM</MenuItem>
                <MenuItem value="CAF">CAF</MenuItem>
                <MenuItem value="TCD">TCD</MenuItem>
                <MenuItem value="CHL">CHL</MenuItem>
                <MenuItem value="CHN">CHN</MenuItem>
                <MenuItem value="CXR">CXR</MenuItem>
                <MenuItem value="CCK">CCK</MenuItem>
                <MenuItem value="COL">COL</MenuItem>
                <MenuItem value="COM">COM</MenuItem>
                <MenuItem value="COD">COD</MenuItem>
                <MenuItem value="COG">COG</MenuItem>
                <MenuItem value="COK">COK</MenuItem>
                <MenuItem value="CRI">CRI</MenuItem>
                <MenuItem value="HRV">HRV</MenuItem>
                <MenuItem value="CUB">CUB</MenuItem>
                <MenuItem value="CUW">CUW</MenuItem>
                <MenuItem value="CYP">CYP</MenuItem>
                <MenuItem value="CZE">CZE</MenuItem>
                <MenuItem value="CIV">CIV</MenuItem>
                <MenuItem value="DNK">DNK</MenuItem>
                <MenuItem value="DJI">DJI</MenuItem>
                <MenuItem value="DMA">DMA</MenuItem>
                <MenuItem value="DOM">DOM</MenuItem>
                <MenuItem value="ECU">ECU</MenuItem>
                <MenuItem value="EGY">EGY</MenuItem>
                <MenuItem value="SLV">SLV</MenuItem>
                <MenuItem value="GNQ">GNQ</MenuItem>
                <MenuItem value="ERI">ERI</MenuItem>
                <MenuItem value="EST">EST</MenuItem>
                <MenuItem value="SWZ">SWZ</MenuItem>
                <MenuItem value="ETH">ETH</MenuItem>
                <MenuItem value="FLK">FLK</MenuItem>
                <MenuItem value="FRO">FRO</MenuItem>
                <MenuItem value="FJI">FJI</MenuItem>
                <MenuItem value="FIN">FIN</MenuItem>
                <MenuItem value="FRA">FRA</MenuItem>
                <MenuItem value="GUF">GUF</MenuItem>
                <MenuItem value="PYF">PYF</MenuItem>
                <MenuItem value="ATF">ATF</MenuItem>
                <MenuItem value="GAB">GAB</MenuItem>
                <MenuItem value="GMB">GMB</MenuItem>
                <MenuItem value="GEO">GEO</MenuItem>
                <MenuItem value="DEU">DEU</MenuItem>
                <MenuItem value="GHA">GHA</MenuItem>
                <MenuItem value="GIB">GIB</MenuItem>
                <MenuItem value="GRC">GRC</MenuItem>
                <MenuItem value="GRL">GRL</MenuItem>
                <MenuItem value="GRD">GRD</MenuItem>
                <MenuItem value="GLP">GLP</MenuItem>
                <MenuItem value="GUM">GUM</MenuItem>
                <MenuItem value="GTM">GTM</MenuItem>
                <MenuItem value="GGY">GGY</MenuItem>
                <MenuItem value="GIN">GIN</MenuItem>
                <MenuItem value="GNB">GNB</MenuItem>
                <MenuItem value="GUY">GUY</MenuItem>
                <MenuItem value="HTI">HTI</MenuItem>
                <MenuItem value="HMD">HMD</MenuItem>
                <MenuItem value="VAT">VAT</MenuItem>
                <MenuItem value="HND">HND</MenuItem>
                <MenuItem value="HKG">HKG</MenuItem>
                <MenuItem value="HUN">HUN</MenuItem>
                <MenuItem value="ISL">ISL</MenuItem>
                <MenuItem value="IND">IND</MenuItem>
                <MenuItem value="IDN">IDN</MenuItem>
                <MenuItem value="IRN">IRN</MenuItem>
                <MenuItem value="IRQ">IRQ</MenuItem>
                <MenuItem value="IRL">IRL</MenuItem>
                <MenuItem value="IMN">IMN</MenuItem>
                <MenuItem value="ISR">ISR</MenuItem>
                <MenuItem value="ITA">ITA</MenuItem>
                <MenuItem value="JAM">JAM</MenuItem>
                <MenuItem value="JPN">JPN</MenuItem>
                <MenuItem value="JEY">JEY</MenuItem>
                <MenuItem value="JOR">JOR</MenuItem>
                <MenuItem value="KAZ">KAZ</MenuItem>
                <MenuItem value="KEN">KEN</MenuItem>
                <MenuItem value="KIR">KIR</MenuItem>
                <MenuItem value="PRK">PRK</MenuItem>
                <MenuItem value="KOR">KOR</MenuItem>
                <MenuItem value="KWT">KWT</MenuItem>
                <MenuItem value="KGZ">KGZ</MenuItem>
                <MenuItem value="LAO">LAO</MenuItem>
                <MenuItem value="LVA">LVA</MenuItem>
                <MenuItem value="LBN">LBN</MenuItem>
                <MenuItem value="LSO">LSO</MenuItem>
                <MenuItem value="LBR">LBR</MenuItem>
                <MenuItem value="LBY">LBY</MenuItem>
                <MenuItem value="LIE">LIE</MenuItem>
                <MenuItem value="LTU">LTU</MenuItem>
                <MenuItem value="LUX">LUX</MenuItem>
                <MenuItem value="MAC">MAC</MenuItem>
                <MenuItem value="MDG">MDG</MenuItem>
                <MenuItem value="MWI">MWI</MenuItem>
                <MenuItem value="MYS">MYS</MenuItem>
                <MenuItem value="MDV">MDV</MenuItem>
                <MenuItem value="MLI">MLI</MenuItem>
                <MenuItem value="MLT">MLT</MenuItem>
                <MenuItem value="MHL">MHL</MenuItem>
                <MenuItem value="MTQ">MTQ</MenuItem>
                <MenuItem value="MRT">MRT</MenuItem>
                <MenuItem value="MUS">MUS</MenuItem>
                <MenuItem value="MYT">MYT</MenuItem>
                <MenuItem value="MEX">MEX</MenuItem>
                <MenuItem value="FSM">FSM</MenuItem>
                <MenuItem value="MDA">MDA</MenuItem>
                <MenuItem value="MCO">MCO</MenuItem>
                <MenuItem value="MNG">MNG</MenuItem>
                <MenuItem value="MNE">MNE</MenuItem>
                <MenuItem value="MSR">MSR</MenuItem>
                <MenuItem value="MAR">MAR</MenuItem>
                <MenuItem value="MOZ">MOZ</MenuItem>
                <MenuItem value="MMR">MMR</MenuItem>
                <MenuItem value="NAM">NAM</MenuItem>
                <MenuItem value="NRU">NRU</MenuItem>
                <MenuItem value="NPL">NPL</MenuItem>
                <MenuItem value="NLD">NLD</MenuItem>
                <MenuItem value="NCL">NCL</MenuItem>
                <MenuItem value="NZL">NZL</MenuItem>
                <MenuItem value="NIC">NIC</MenuItem>
                <MenuItem value="NER">NER</MenuItem>
                <MenuItem value="NGA">NGA</MenuItem>
                <MenuItem value="NIU">NIU</MenuItem>
                <MenuItem value="NFK">NFK</MenuItem>
                <MenuItem value="MNP">MNP</MenuItem>
                <MenuItem value="NOR">NOR</MenuItem>
                <MenuItem value="OMN">OMN</MenuItem>
                <MenuItem value="PAK">PAK</MenuItem>
                <MenuItem value="PLW">PLW</MenuItem>
                <MenuItem value="PSE">PSE</MenuItem>
                <MenuItem value="PAN">PAN</MenuItem>
                <MenuItem value="PNG">PNG</MenuItem>
                <MenuItem value="PRY">PRY</MenuItem>
                <MenuItem value="PER">PER</MenuItem>
                <MenuItem value="PHL">PHL</MenuItem>
                <MenuItem value="PCN">PCN</MenuItem>
                <MenuItem value="POL">POL</MenuItem>
                <MenuItem value="PRT">PRT</MenuItem>
                <MenuItem value="PRI">PRI</MenuItem>
                <MenuItem value="QAT">QAT</MenuItem>
                <MenuItem value="MKD">MKD</MenuItem>
                <MenuItem value="ROU">ROU</MenuItem>
                <MenuItem value="RUS">RUS</MenuItem>
                <MenuItem value="RWA">RWA</MenuItem>
                <MenuItem value="REU">REU</MenuItem>
                <MenuItem value="BLM">BLM</MenuItem>
                <MenuItem value="SHN">SHN</MenuItem>
                <MenuItem value="KNA">KNA</MenuItem>
                <MenuItem value="LCA">LCA</MenuItem>
                <MenuItem value="MAF">MAF</MenuItem>
                <MenuItem value="SPM">SPM</MenuItem>
                <MenuItem value="VCT">VCT</MenuItem>
                <MenuItem value="WSM">WSM</MenuItem>
                <MenuItem value="SMR">SMR</MenuItem>
                <MenuItem value="STP">STP</MenuItem>
                <MenuItem value="SAU">SAU</MenuItem>
                <MenuItem value="SEN">SEN</MenuItem>
                <MenuItem value="SRB">SRB</MenuItem>
                <MenuItem value="SYC">SYC</MenuItem>
                <MenuItem value="SLE">SLE</MenuItem>
                <MenuItem value="SGP">SGP</MenuItem>
                <MenuItem value="SXM">SXM</MenuItem>
                <MenuItem value="SVK">SVK</MenuItem>
                <MenuItem value="SVN">SVN</MenuItem>
                <MenuItem value="SLB">SLB</MenuItem>
                <MenuItem value="SOM">SOM</MenuItem>
                <MenuItem value="ZAF">ZAF</MenuItem>
                <MenuItem value="SGS">SGS</MenuItem>
                <MenuItem value="SSD">SSD</MenuItem>
                <MenuItem value="ESP">ESP</MenuItem>
                <MenuItem value="LKA">LKA</MenuItem>
                <MenuItem value="SDN">SDN</MenuItem>
                <MenuItem value="SUR">SUR</MenuItem>
                <MenuItem value="SJM">SJM</MenuItem>
                <MenuItem value="SWE">SWE</MenuItem>
                <MenuItem value="CHE">CHE</MenuItem>
                <MenuItem value="SYR">SYR</MenuItem>
                <MenuItem value="TWN">TWN</MenuItem>
                <MenuItem value="TJK">TJK</MenuItem>
                <MenuItem value="TZA">TZA</MenuItem>
                <MenuItem value="THA">THA</MenuItem>
                <MenuItem value="TLS">TLS</MenuItem>
                <MenuItem value="TGO">TGO</MenuItem>
                <MenuItem value="TKL">TKL</MenuItem>
                <MenuItem value="TON">TON</MenuItem>
                <MenuItem value="TTO">TTO</MenuItem>
                <MenuItem value="TUN">TUN</MenuItem>
                <MenuItem value="TUR">TUR</MenuItem>
                <MenuItem value="TKM">TKM</MenuItem>
                <MenuItem value="TCA">TCA</MenuItem>
                <MenuItem value="TUV">TUV</MenuItem>
                <MenuItem value="UGA">UGA</MenuItem>
                <MenuItem value="UKR">UKR</MenuItem>
                <MenuItem value="ARE">ARE</MenuItem>
                <MenuItem value="GBR">GBR</MenuItem>
                <MenuItem value="UMI">UMI</MenuItem>
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="URY">URY</MenuItem>
                <MenuItem value="UZB">UZB</MenuItem>
                <MenuItem value="VUT">VUT</MenuItem>
                <MenuItem value="VEN">VEN</MenuItem>
                <MenuItem value="VNM">VNM</MenuItem>
                <MenuItem value="VGB">VGB</MenuItem>
                <MenuItem value="VIR">VIR</MenuItem>
                <MenuItem value="WLF">WLF</MenuItem>
                <MenuItem value="ESH">ESH</MenuItem>
                <MenuItem value="YEM">YEM</MenuItem>
                <MenuItem value="ZMB">ZMB</MenuItem>
                <MenuItem value="ZWE">ZWE</MenuItem>
            </Select>
        </FormControl>

        <p>Todo: lookup each 3 letter country code and replace with full country name</p>

    </>
});