import {FC} from 'react';
import { observer } from 'mobx-react-lite';
import Typography from "@mui/material/Typography";
import * as React from "react";
import Container from "@mui/material/Container";
import {useTitle} from "../../hooks/useTitle";

export const Home: FC = observer(function () {

  useTitle('Suubee Portfolios | Home');

  return (
      <>
        <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
          <Typography
              component="h1"
              variant="h2"
              align="center"
              gutterBottom
          >Hi.
          </Typography>
          <Typography variant="h5" align="center" component="p">
              This product is currently undergoing early user testing. If you would like to learn more or get access to this product, please contact us at <a href="mailto:info@suubee.com">info@suubee.com</a>.
          </Typography>
        </Container>
      </>
  );
});
