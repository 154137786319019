import ky from 'ky';
import {Instance, types} from 'mobx-state-tree';
import {getDefaultAjaxInstance} from './util';
import {IAuthenticationProvider} from 'auth/AuthenticationTypes';
import {SecurityModel} from './SecurityModel';
import * as moment from 'moment-timezone';
import {ListsModel} from "./ListsModel";
import {AccountStore} from "./AccountModel";

export type ModelLoadingState = 'loading' | 'done' | 'error' | 'unauthorized';

export const LoadingStateModel = types.optional(
    types.enumeration<ModelLoadingState>(['loading', 'done', 'error', 'unauthorized']),
    'loading'
);

const RootStoreModel = types.model(`RootStoreModel`, {
    state: LoadingStateModel,
    security: types.optional(SecurityModel, {}),
    defaultUserTimezone: types.string,
    listsStore: types.optional(ListsModel, {}),
    accountStore: types.optional(AccountStore, {}),
});

export interface IRootStoreModel extends Instance<typeof RootStoreModel> {
}

export interface IStoreEnvironment {
    ajax: typeof ky;
    auth: IAuthenticationProvider;
}

export function getDefaultStore(auth: IAuthenticationProvider): IRootStoreModel {
    const ajax = getDefaultAjaxInstance(auth);
    const defaultUserTimezone = localStorage.getItem('userTimezone') ?? moment.tz.guess();

    return RootStoreModel.create(
        {state: 'loading', defaultUserTimezone: defaultUserTimezone},
        {ajax, auth}
    );
}
