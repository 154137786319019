import React, {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styles from './Portfolio.module.scss';
import Grid from "@mui/material/Grid";
import {useStore} from "../../hooks";
import Slider from '@mui/material/Slider';
import {Skeleton, TableBody, TableFooter, TableHead, TextField} from "@mui/material";
import {chartDataItem, SubscriptionPieChart} from "./SubscriptionPieChart";
import _ from 'lodash';
import Button from "@mui/material/Button";
import SquareIcon from '@mui/icons-material/Square';
import {listSubscription} from "../../store/AccountModel";
import {IListDetailModel, IListModel} from "../../store/ListsModel";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {CSVLink} from "react-csv";
import moment from "moment";

interface IBlendedPortfolioModelRecord {
    securityCode: string,
    targetWeightingBps: number
    lists: string[],
}

interface portfolioProps{
    isUS: boolean;
}


export const Portfolio : FC<portfolioProps> = observer(({isUS}) => {

    const {listsStore, accountStore} = useStore();
    const [scopedLists, setScopedLists] = useState<IListModel[]>([]);
    const [pendingUnallocated, setPendingUnallocated] = useState<number>(100);
    const [currentCash, setCurrentCash] = useState<number>(0);
    const [pendingCash, setPendingCash] = useState<number>(0);
    const [currentUnallocated, setCurrentUnallocated] = useState<number>(100);
    const [pendingAllocation, setPendingAllocation] = useState<number[] | null>(null);
    const [currentAllocation, setCurrentAllocation] = useState<number[] | null>(null);
    const [hasPendingRequest, setHasPendingRequest] = React.useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [blendedModelPortfolio, setBlendedModelPortfolio] = useState<IBlendedPortfolioModelRecord[]>([]);
    const [csvData, setCsvData] = useState<string[][]>([]);
    
    const cashColor = '#D83F87';

    const loadAccount = useCallback(() => {
        setIsLoading(true);
        var currentAllocatedPercentage = 0;
        var pendingAllocationPercentage = 0;

        listsStore.loadLists().then(() => {
            
            const _scopedLists = listsStore.lists.filter(l => l.isUSList === isUS);
            
            setScopedLists(_scopedLists);
            
            const currentAllocation = _scopedLists.map(l => 0);
            var pendingAllocation = _scopedLists.map(l => 0);

            return accountStore.loadAccount().then(() => {

                const currentSubscription = isUS ? accountStore.account!.currentUsSubscription : accountStore.account!.currentSubscription;

                if (currentSubscription) {
                    const subscription = JSON.parse(currentSubscription.subscriptionComposition) as {
                        List: string,
                        Allocation: number
                    }[];

                    subscription.forEach(s => {
                        const indexOfList = _scopedLists.findIndex(l => l.name === s.List);

                        if (indexOfList === -1) {
                            console.error("List not found: " + s.List);
                        }

                        currentAllocation[indexOfList] = s.Allocation as number;
                        currentAllocatedPercentage += s.Allocation as number;
                    });
                }

                const requestSubscription = isUS? accountStore.account!.requestedUsSubscription : accountStore.account!.requestedSubscription;
                if (requestSubscription) {
                    const subscription = JSON.parse(requestSubscription.subscriptionComposition) as {
                        List: string,
                        Allocation: number
                    }[];

                    subscription.forEach(s => {
                        const indexOfList = _scopedLists.findIndex(l => l.name === s.List);

                        if (indexOfList === -1) {
                            console.error("List not found: " + s.List);
                        }

                        pendingAllocation[indexOfList] = s.Allocation as number;
                        pendingAllocationPercentage += s.Allocation as number;
                    })
                    setHasPendingRequest(true);
                } else {
                    pendingAllocation = currentAllocation;
                    pendingAllocationPercentage = currentAllocatedPercentage;

                    //calculate current allocated to cash
                    let tmpTotalCash = 0;
                    _scopedLists.forEach((l, i) => {
                        let listAllocation = currentAllocation![i];
                        let cashForList = listAllocation / 100 * l.cashAllocationBasisPoints / 100;
                        tmpTotalCash = tmpTotalCash + (cashForList);
                    });
                    setCurrentCash(tmpTotalCash)
                }

                setPendingUnallocated(100 - pendingAllocationPercentage);
                setCurrentUnallocated(100 - currentAllocatedPercentage);
                setPendingAllocation(pendingAllocation);
                setCurrentAllocation(currentAllocation);
            })
        }).finally(() => {
            setIsLoading(false)
        });

    }, [accountStore, listsStore, setScopedLists]);

    useEffect(() => {
        loadAccount();
    }, [loadAccount]);

    useEffect(() => {
        let tmpTotalCash = 0;

        if (scopedLists.length === 0  || !pendingAllocation) return;

        scopedLists.forEach((l, i) => {
            let listAllocation = pendingAllocation![i];
            let cashForList = listAllocation / 100 * l.cashAllocationBasisPoints / 100;
            tmpTotalCash = tmpTotalCash + (cashForList);
        });
        setPendingCash(tmpTotalCash)
    }, [pendingAllocation]);


    useEffect(() => {
        setIsLoading (true);
        
        const currentSubscription = isUS ? accountStore.account!.currentUsSubscription : accountStore.account!.currentSubscription;
        
        if (currentSubscription) {
            const subscriptions = JSON.parse(currentSubscription.subscriptionComposition) as listSubscription[];

            let getListsPromises: Promise<any>[] = [];

            subscriptions.filter((subscription) => subscription.Allocation > 0).forEach((subscription) => {
                getListsPromises.push(listsStore.loadListDetails(subscription.List));
            });

            Promise.all(getListsPromises).then((listDetails: IListDetailModel[]) => {

                let blendedModel: IBlendedPortfolioModelRecord[] = [];

                subscriptions.filter(sub => sub.Allocation > 0).forEach((listSubscription: listSubscription) => {
                    const listAllocation = listSubscription.Allocation;
                    const listName = listSubscription.List;

                    const listDetail = listDetails
                        .find((listDetail) => listDetail.name === listName);

                    if (!listDetail) {
                        throw new Error(`List Details not found: ${listName}`);
                    }

                    listDetail.stocks.filter(s => !s.isCash).forEach((stock) => {

                        if (stock.lastPriceInCents === null || stock.lastPriceInCents === 0) {
                            console.log(`Stock has no last price - ${stock.code} - skipping`);
                            return;
                        }

                        let listAllocationPercentage = listAllocation / 100;
                        let stockAllocationPercentage = stock.weightingBasisPoints / 100 / 100;

                        const doesRebalancerRecordExist: boolean = blendedModel.some((rebalanceRecord) => rebalanceRecord.securityCode === stock.code);

                        // target weighting to two decimal places
                        if (!doesRebalancerRecordExist) {
                            blendedModel.push({
                                securityCode: stock.code,
                                lists: [listName],
                                targetWeightingBps: listAllocationPercentage * stockAllocationPercentage 
                            });
                        } else {
                            const existingRecordIndex = blendedModel.findIndex((rebalanceRecord) => rebalanceRecord.securityCode === stock.code);
                            const existingRecord = blendedModel[existingRecordIndex];
                            blendedModel.splice(existingRecordIndex, 1);
                            existingRecord.targetWeightingBps = existingRecord.targetWeightingBps + (listAllocationPercentage * stockAllocationPercentage);
                            existingRecord.lists.push(listName);
                            blendedModel.push(existingRecord);
                        }
                    });
                });

                setBlendedModelPortfolio(blendedModel.sort((a, b) => b.targetWeightingBps - a.targetWeightingBps));

            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [accountStore.account])

    const generateCsvData = () => {
        let data : string[][] = [];
        
        //There's no header
        //data.push(["DES?", "Ticker", "STK", "Exchange", "Blank1","Blank2","Blank3","Blank4","Blank5","Weighting"]);

        blendedModelPortfolio.forEach((record) => {
                let securityCodeTokens = record.securityCode.split(".");
                const securitySymbol = securityCodeTokens[0];
                let exchange = securityCodeTokens[1];
                const weighting = (Math.floor(record.targetWeightingBps * 100 * 100) / 100).toFixed(2);

            // let line = `DES,${securitySymbol},STK,SMART/${exchange},,,,,,${weighting}`;
            
            if( exchange != 'ASX') // for SMART routing, all US exchanges use "AMEX" as the exchange, rather than the listed exchange
                exchange = 'AMEX';

            data.push(["DES", securitySymbol, "STK", "SMART/" + exchange, "", "", "", "", "", weighting]);
        });

        setCsvData(data);
    };

    const recordRebalanceCompleted = () => {
        accountStore.recordRebalanceCompleted(blendedModelPortfolio, isUS);
    };

    const handleSliderChange = (index: number, event: Event) => {
        // @ts-ignore
        const newValue = event.target.value as number;
        const oldVal = pendingAllocation![index];
        const difference = newValue - oldVal;
        const newUnallocatedValue = pendingUnallocated - difference;

        var clone = [...pendingAllocation!];

        clone!.splice(index, 1, newValue);

        if (newUnallocatedValue >= 0) {
            setPendingUnallocated(newUnallocatedValue);
            setPendingAllocation(clone);
        }
    };

    const handleSubscriptionChangeRequest = () => {

        const requestedAllocations = pendingAllocation!.map((allocation, index) => {
            return {list: scopedLists[index].name, allocation}
        });
        setIsLoading(true);

        accountStore.requestSubscriptionChange(requestedAllocations, isUS).then(() => {
            loadAccount();
        });
    }

    const handleCancelSubscriptionChangeRequest = () => {
        setIsLoading(true);
        accountStore.cancelSubscriptionChangeRequest(isUS).then(() => {
            loadAccount();
            setHasPendingRequest(false);
        });
    }

    // const colours = ['#9b9b9b', '#AAD6A0', '#DCBAA9', '#94EDFF', '#0A4C86', '#1D3B58'];
    const colours = ['#D83F87', '#2B89D3', '#FFC300', '#2ECC71', '#9B59B6', '#E67E22', '#D35400', '#7F8C8D', '#C0392B', '#1ABC9C','#FF00FF'];

    var currentPieData = scopedLists.map((l, i) => {
        return {
            name: l.name,
            value: ((currentAllocation ?? [])[scopedLists.findIndex(la => l.name === la.name)]),
            colour: colours[i + 1]
        }
    });
    currentPieData = _.filter(currentPieData, e => e.value > 0);
    if (currentUnallocated > 0)
        currentPieData.push({name: 'Cash', value: currentUnallocated, colour: colours[0]});


    var currentPieCashData: chartDataItem[] = [];

    currentPieData.forEach((l, i) => {

        if (l.name === 'Cash') {
            currentPieCashData.push(l);
        } else {
            let newItem = {
                name: ' ',
                value: ((currentAllocation ?? [])[scopedLists.findIndex(la => l.name === la.name)]),
                colour: l.colour
            }

            if (scopedLists.find(la => l.name === la.name) && scopedLists.find(la => l.name === la.name)!.cashAllocationBasisPoints > 0) {

                let cashForList = newItem.value / 100 * scopedLists.find(la => l.name === la.name)!.cashAllocationBasisPoints / 100;

                newItem.value = newItem.value - cashForList;

                currentPieCashData.push({
                    name: 'Cash',
                    value: cashForList,
                    colour: cashColor
                })
            }

            currentPieCashData.push(newItem);
        }
    });


    var pendingPieData = scopedLists.map((l, i) => {
        return {
            name: l.name,
            value: ((pendingAllocation ?? [])[scopedLists.findIndex(la => l.name === la.name)]),
            colour: colours[i + 1]
        }
    });
    pendingPieData = _.filter(pendingPieData, e => e.value > 0);
    if (pendingUnallocated > 0)
        pendingPieData.push({name: 'Cash', value: pendingUnallocated, colour: colours[0]});

    var pendingPieCashData: chartDataItem[] = [];

    pendingPieData.forEach((l, i) => {

        if (l.name === 'Cash') {
            pendingPieCashData.push(l);
        } else {
            let newItem = {
                name: ' ',
                value: ((pendingAllocation ?? [])[scopedLists.findIndex(la => l.name === la.name)]),
                colour: l.colour
            }

            if (scopedLists.find(la => l.name === la.name) && scopedLists.find(la => l.name === la.name)!.cashAllocationBasisPoints > 0) {

                let cashForList = newItem.value / 100 * scopedLists.find(la => l.name === la.name)!.cashAllocationBasisPoints / 100;

                newItem.value = newItem.value - cashForList;

                pendingPieCashData.push({
                    name: 'Cash',
                    value: cashForList,
                    colour: cashColor
                })
            }

            pendingPieCashData.push(newItem);
        }
    });


    const isCurrentAndPendingEquivalent = _.isEqual(currentAllocation, pendingAllocation);

    const allocatedToCash = Math.trunc(!isCurrentAndPendingEquivalent ? pendingCash : currentCash);

    return (
        <div className={styles.container}>

            <>
                {isLoading ? (
                    <>
                        <Skeleton variant={"text"} height={50}/>
                        <Skeleton variant={"rectangular"} height={250}/>
                        <Grid container>
                            <Grid item lg={6}>
                                <Skeleton variant={"circular"} height={300} width={300}/>
                            </Grid>
                        </Grid>
                    </>

                ) : (
                    <>
                        {!hasPendingRequest && (
                            <>

                                <p>{accountStore.account?.isRetailInvestor ? "Adjust the Suubee Lists sliders to you desired subscription." :
                                    "Adjust your subscription to the Suubee lists by dragging the sliders, then submitting a request for the change."}</p>
                                <Grid container spacing={1} className={styles.rowHeight}>
                                    <Grid item xs={12} md={4}>
                                        <SquareIcon htmlColor={colours[0]} className={styles.square}/> Cash
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <strong>{pendingUnallocated}%</strong>
                                    </Grid>
                                </Grid>

                                {allocatedToCash > 0 && (
                                    <Grid container spacing={1} className={styles.rowHeight}>
                                        <Grid item xs={12} md={4}>
                                            <SquareIcon htmlColor={cashColor} className={styles.square}/> Cash position
                                            in list
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <strong>{allocatedToCash}%</strong>
                                        </Grid>
                                    </Grid>
                                )}


                                {scopedLists?.map((l, k) => (
                                    <Grid container spacing={1} key={k}>
                                        <Grid item xs={12} md={4}>
                                            <SquareIcon htmlColor={colours[k + 1]}
                                                        className={styles.square}/> {l.name} ({pendingAllocation ? pendingAllocation[k] : 0}%)
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Slider
                                                value={pendingAllocation ? pendingAllocation[k] : 0}
                                                onChange={(e) => {
                                                    handleSliderChange(k, e);
                                                }}
                                                valueLabelDisplay="auto"
                                                step={10}
                                                marks
                                                min={0}
                                                max={100}
                                                className={`${styles['color' + (k + 1)]}`}
                                            />
                                        </Grid>
                                    </Grid>
                                ))}               </>)}


                        <Grid container>
                            <Grid item xs={12} md={6} height={400}>
                                <h4>Current</h4>
                                <SubscriptionPieChart data={currentPieData}
                                                      secondaryData={allocatedToCash > 0 ? currentPieCashData : undefined}/>
                            </Grid>
                            {(hasPendingRequest || !isCurrentAndPendingEquivalent) && (
                                <Grid item xs={12} md={6} height={400}>
                                    {hasPendingRequest && (
                                        <>
                                            <h4>Pending Change</h4>
                                            <SubscriptionPieChart data={pendingPieData}
                                                                  secondaryData={allocatedToCash > 0 ? pendingPieCashData : undefined}/>
                                        </>
                                    )}

                                    {((!hasPendingRequest && !isCurrentAndPendingEquivalent)) && (
                                        <>
                                            <h4>{accountStore.account?.isRetailInvestor ? "Change to" : "Change to"}</h4>
                                            <SubscriptionPieChart data={pendingPieData}
                                                                  secondaryData={allocatedToCash > 0 ? pendingPieCashData : undefined}/>
                                        </>
                                    )}
                                </Grid>
                            )}
                            {isCurrentAndPendingEquivalent && accountStore.account?.isRetailInvestor && blendedModelPortfolio.length > 0 && (
                                <Grid item xs={12} md={6}>
                                    <h4>Blended Portfolio</h4>
                                    <Table size="small">
                                        <TableHead className={styles.rowHeader}>
                                            <TableRow>
                                                <TableCell>List</TableCell>
                                                <TableCell>Ticker</TableCell>
                                                <TableCell colSpan={2}>Weighting (%)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(blendedModelPortfolio).map((rebalanceRecord: IBlendedPortfolioModelRecord, key) => (
                                                <TableRow key={key}>
                                                    <TableCell>{rebalanceRecord.lists.join(", ")}</TableCell>
                                                    <TableCell>{rebalanceRecord.securityCode}</TableCell>
                                                    <TableCell align={"right"}>
                                                         {(Math.floor(Math.abs(rebalanceRecord.targetWeightingBps) * 100 * 100) / 100).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {rebalanceRecord.targetWeightingBps < 0? " (Short)" : <></>}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan={2} align={"right"}>Total</TableCell>
                                                <TableCell align={"right"}>
                                                    {blendedModelPortfolio.reduce((a, b) => a + (Math.floor(Math.abs(b.targetWeightingBps) * 100 * 100) / 100), 0).toFixed(2)}
                                                </TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align={"right"}>                    
                                                    <CSVLink data={csvData} onClick={() => {generateCsvData(); recordRebalanceCompleted();}} enclosingCharacter={''} filename={moment().format('YYYYMMDD') +"-suubee-portfolio"+ (isUS? "-us":"-au") +".csv"}>
                                                    Download IB Blended Portfolio CSV
                                                </CSVLink>
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                            </Grid>
                            {(hasPendingRequest || !isCurrentAndPendingEquivalent) && (
                                <Grid item xs={12} md={6}>
                                    {accountStore.account?.isSection708Investor && (
                                        <>
                                            {hasPendingRequest && (
                                                <Button color="primary" variant="contained"
                                                        onClick={handleCancelSubscriptionChangeRequest}>Cancel
                                                    Request</Button>
                                            )}

                                            {!hasPendingRequest && !isCurrentAndPendingEquivalent && (
                                                <Button color="primary" variant="contained"
                                                        onClick={handleSubscriptionChangeRequest}>Request
                                                    Change</Button>
                                            )}
                                        </>
                                    )}

                                    {!isCurrentAndPendingEquivalent && accountStore.account?.isRetailInvestor && (
                                        <Button color="primary" variant="contained"
                                                onClick={handleSubscriptionChangeRequest}>
                                            Save
                                        </Button>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}</>
        </div>
    );
});

