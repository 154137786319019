import {flow, Instance, types} from 'mobx-state-tree';
import {getEnv} from './util';


export const NewClientModel = types.model('NewClientModel', {
    id: types.identifierNumber,
    email: types.string,
    inviteUrl: types.string
});

export const SubscriptionModel = types
    .model('SubscriptionModel', {
        id: types.identifierNumber,
        status: types.string,
        subscriptionComposition: types.string,
        requestCreatedTimeStamp: types.string,
        requestCompletedTimeStamp: types.maybeNull(types.string),
        requestCancelledTimeStamp: types.maybeNull(types.string),
        requestedBy: types.string,
        requestCompletedBy: types.maybeNull(types.string),
        requestCancelledBy: types.maybeNull(types.string),
        subscriptionInactiveTimeStamp: types.maybeNull(types.string)
    });

export const AccountModel = types.model('AccountModel', {
    id: types.identifierNumber,
    email: types.string,
    name: types.string,
    currentSubscription: types.maybeNull(SubscriptionModel),
    currentUsSubscription: types.maybeNull(SubscriptionModel),
    requestedSubscription: types.maybeNull(SubscriptionModel),
    requestedUsSubscription: types.maybeNull(SubscriptionModel),
    isRetailInvestor: types.boolean,
    isSection708Investor: types.boolean,
    brokerageAccount: types.maybeNull(types.string),
    lastRebalancedTimestamp: types.maybeNull(types.string),
    lastRebalancedBlendedPortfolio: types.maybeNull(types.string),
    lastUsRebalancedTimestamp: types.maybeNull(types.string),
    lastUsRebalancedBlendedPortfolio: types.maybeNull(types.string)
});

export const HoldingModel = types.model('HoldingModel', {
    averageCostInCents : types.number,
    marketCode : types.string,
    securityCode: types.string,
    totalHolding: types.number,
    securityDescription: types.string,
    marketPriceInCents: types.number,
    marketValueInCents: types.number,
    marketPriceDate: types.string,
    unrealizedPnlInCents: types.number,
    lists: types.array(types.string)
});

export const ClientAccountDetailsItem = types.model('ClientAccountDetailsItem', {
    amount: types.number,
    currency: types.string,
    isNull: types.boolean,
    timeStamp: types.string,
    value: types.string,
    amountInCents: types.number,
    severity: types.string
});
    

export const ClientAccountDetails = types.model('ClientAccountDetails', {
    accountCode: ClientAccountDetailsItem,
    accountReady: ClientAccountDetailsItem,
    accountType: ClientAccountDetailsItem,
    netLiquidation: ClientAccountDetailsItem,
});

export interface ISubscriptionModel extends Instance<typeof SubscriptionModel> {
}

export interface IAccountModel extends Instance<typeof AccountModel> {
    currentSubscription: ISubscriptionModel,
    requestedSubscription: ISubscriptionModel,
    currentUsSubscription: ISubscriptionModel,
    requestedUsSubscription: ISubscriptionModel
}

export type SubscriptionCompositionType = ({ List: string, Allocation: number })[];
export interface IHoldingModel extends Instance<typeof HoldingModel>{}
export interface INewClientModel extends Instance<typeof NewClientModel>{}
export interface IClientAccountDetails extends Instance<typeof ClientAccountDetails>{}

export interface listSubscription {
    List: string,
    Allocation: number
}

export const AccountStore = types
    .model('AccountStore', {
        account: types.maybeNull(AccountModel),
        lastRebalancedTimestamp: types.maybeNull(types.string),
        lastUsRebalancedTimestamp: types.maybeNull(types.string),
    })
    .actions((self) => ({
        loadAccount: flow(function* () {
            const {ajax} = getEnv(self);

            try {
                self.account = yield ajax
                    .get('account/')
                    .json<IAccountModel>()
                    .then((result) => {
                        return AccountModel.create(result);
                    })
                self.lastRebalancedTimestamp = self.account?.lastRebalancedTimestamp ?? null;
            } catch (error) {
                console.log('error loadAccount : ' + error);
            }
        }),
        loadAccountsWithPendingSubscription: flow(function* () {
            const {ajax} = getEnv(self);

            try {
                return yield ajax
                    .get('admin/getsubscriptionrequests')
                    .json<IAccountModel[]>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error admin/getsubscriptionrequests : ' + error);
            }
        }),
        markSubscriptionChangeRequestAsComplete: flow(function* (requestId: number) {
            const {ajax} = getEnv(self);

            try {
                return yield ajax
                    .post('admin/markSubscriptionChangeRequestAsComplete/' + requestId)
                    .json<boolean>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error admin/markSubscriptionChangeRequestAsComplete : ' + error);
            }
        }),
        markSubscriptionChangeRequestAsRejected: flow(function* (requestId: number) {
            const {ajax} = getEnv(self);

            try {
                return yield ajax
                    .post('admin/markSubscriptionChangeRequestAsRejected/' + requestId)
                    .json<boolean>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error admin/markSubscriptionChangeRequestAsRejected : ' + error);
            }
        }),
        isAdmin: flow(function* () {
            const {ajax} = getEnv(self);

            try {
                return yield ajax
                    .get('admin/isadmin')
                    .json<boolean>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error admin/isadmin : ' + error);
            }
            
        }),
        requestSubscriptionChange: flow(function* (subscriptionComposition: { list: string, allocation: number }[], isUS : boolean) {
            const {ajax} = getEnv(self);

            try {
                self.account = yield ajax
                    .post('account/requestSubscriptionChange', {json: {subscriptionComposition, isUS}})
                    .json<boolean>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error requestSubscriptionChange : ' + error);
            }
        }),
        cancelSubscriptionChangeRequest: flow(function* (isUS: boolean) {
            const {ajax} = getEnv(self);

            try {
                self.account = yield ajax
                    .post('account/cancelSubscriptionChangeRequest', {json: {isUS}})
                    .json<boolean>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error cancelSubscriptionChangeRequest : ' + error);
            }
        }),
        loadHoldings:  flow(function* () {
            const {ajax} = getEnv(self);

            try {
                return yield ajax
                    .get('account/holdings')
                    .json<IHoldingModel[]>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error loadHoldings : ' + error);
            }
        }),
        loadClientAccountDetails: flow(function* () {
            const {ajax} = getEnv(self);

            try {
                return yield ajax
                    .get('account/clientaccountdetails')
                    .json<IClientAccountDetails>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error loadClientAccountDetails : ' + error);
            }
        }),
        inviteNewClient: flow(function* (email: string, name:string, brokerageAccountNumber: string, accountType: string) {
            const {ajax} = getEnv(self);

            try {
                return yield ajax
                    .post('admin/inviteNewClient', {json: {email, name, brokerageAccountNumber, accountType}})
                    .json<INewClientModel>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error inviteNewClient : ' + error);
            }
        }),
        recordRebalanceCompleted: flow(function* (blendedPortfolioRecords, isUS) {
            const {ajax} = getEnv(self);

            try {
                if(isUS){
                    self.lastUsRebalancedTimestamp =  new Date().toISOString();
                }else{
                    self.lastRebalancedTimestamp =  new Date().toISOString();
                }

                return yield ajax
                    .post('account/recordRebalanceCompleted', {json: {blendedPortfolioRecords: blendedPortfolioRecords, isUS: isUS}})
                    .json<boolean>()
                    .then((result) => {
                        return result;
                    })
            } catch (error) {
                console.log('error recordRebalanceCompleted : ' + error);
            }
        }),
    }))
    .views((self) => ({}));
