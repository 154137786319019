import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {FC} from "react";
import styles from './SignUp.module.scss'
import {CountryComponent} from "./CountryComponent";
import {AddressComponent} from "./AddressComponent";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const isAu = true;


export const Applicant: FC = () => {
    return <div className={styles.stepContainer}>
        <h3>Applicant</h3>

        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <h4>Applicant</h4>
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup row={true} sx={{p: 0}}>
                    <FormControl sx={{minWidth: 120}} required>
                        <InputLabel id="title-label">Title</InputLabel>
                        <Select
                            labelId="title-label"
                            id="title-select"
                            value={''}
                            label="Title"
                            onChange={() => {
                            }}
                        >
                            <MenuItem value={'Miss'}>Miss</MenuItem>
                            <MenuItem value={'Mr'}>Mr</MenuItem>
                            <MenuItem value={'Mrs'}>Mrs</MenuItem>
                            <MenuItem value={'Ms'}>Ms</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField id="givenName" label="Given Name" required/>
                    <TextField id="middleName" label="Middle Name"/>
                    <TextField id="familyName" label="Family Name" required/>
                    <TextField id="alias" label="Alias"/>
                </FormGroup>

                <p>Other titles supported by API - do we want these? Adml, Att, Bm, Brig, Bshp, Capt, Clr, Cmdr, Col,
                    Comm, Cpl,
                    Dame, Dr, Est, Flt, Fr, GCpt, Hon, Jdg, Lady, Lt, LtCl, Maj, Mdm, Msrs, Mstr, Mx, Prof, Pstr, Rab,
                    Rev,
                    Sen, Sgt, Sir, Sr, WCmd</p>

                <TextField id="dateOfBirth" label="Date of birth" placeholder="yyyy-MM-dd" required/>
                <CountryComponent label="Nationality"/>

                <FormControl sx={{minWidth: 200}} required>
                    <InputLabel id="occupation-label">Occupation</InputLabel>
                    <Select
                        labelId="occupation-label"
                        id="occupation-select"
                        value={''}
                        label="Occupation"
                        required
                        onChange={() => {
                        }}
                    >
                        <MenuItem value={'CLERICAL_AND_ADMINISTRATIVE_WORKERS'}>Clerical and Administrative
                            Workers</MenuItem>
                        <MenuItem value={'COMMUNITY_AND_PERSONAL_SERVICE_WORKERS'}>Community and Personal Service
                            Workers</MenuItem>
                        <MenuItem value={'HOMEMAKER'}>Homemaker</MenuItem>
                        <MenuItem value={'LABOURER'}>Labourer</MenuItem>
                        <MenuItem value={'MACHINERY_OPERATORS_AND_DRIVERS'}>Machinery Operators and Drivers</MenuItem>
                        <MenuItem value={'MANAGERS'}>Managers</MenuItem>
                        <MenuItem value={'MILITARY'}>Military</MenuItem>
                        <MenuItem value={'PROFESSIONAL'}>Professional</MenuItem>
                        <MenuItem value={'RETIRED'}>Retired</MenuItem>
                        <MenuItem value={'SALES_WORKERS'}>Sales Workers</MenuItem>
                        <MenuItem value={'SELF_EMPLOYED'}>Self Employed</MenuItem>
                        <MenuItem value={'STUDENT'}>Student</MenuItem>
                        <MenuItem value={'TECHNICIANS_AND_TRADE_WORKERS'}>Technicians and Trade Workers</MenuItem>
                        <MenuItem value={'UNEMPLOYED'}>Unemployed</MenuItem>
                        <MenuItem value={'OTHER'}>Other (please specify)</MenuItem>
                    </Select>
                </FormControl>

                <TextField id="occupation-other" label="Occupation Description" required/>
                <p>Only show "Occupation Description" if Other is selected</p>

                <FormControl sx={{minWidth: 200}} required>
                    <InputLabel id="sourceOfWealth-label">Source of Funding</InputLabel>
                    <Select
                        labelId="sourceOfWealth-label"
                        id="sourceOfWealth-select"
                        value={''}
                        label="Source of Funding"
                        required
                        onChange={() => {
                        }}
                    >
                        <MenuItem value={'EMPLOYMENT'}>Employment</MenuItem>
                        <MenuItem value={'GIFT_WINDFALL'}>Gift Windfall</MenuItem>
                        <MenuItem value={'INHERITANCE'}>Inheritance</MenuItem>
                        <MenuItem value={'INVESTMENTS_AUSTRALIA'}>Investments Australia</MenuItem>
                        <MenuItem value={'INVESTMENTS_SAVINGS'}>Investments Savings</MenuItem>
                        <MenuItem value={'SAVINGS'}>Savings</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{minWidth: 200}} required>
                    <InputLabel id="roles-label">Relationships to Account</InputLabel>
                    <Select
                        multiple
                        labelId="roles-label"
                        id="roles-select"
                        value={[]}
                        label="roles"
                        required
                        onChange={() => {
                        }}
                    >
                        <MenuItem value={'ACCOUNTANT'}>Accountant</MenuItem>
                        <MenuItem value={'ADVISER'}>Adviser</MenuItem>
                        <MenuItem value={'AUTHORISED_AGENT'}>Authorised Agent</MenuItem>
                        <MenuItem value={'AUTHORISED_SIGNATORY'}>Authorised Signatory</MenuItem>
                        <MenuItem value={'AUTHORISED_TRADER'}>Authorised Trader</MenuItem>
                        <MenuItem value={'BENEFICIAL_OWNER'}>Beneficial Owner</MenuItem>
                        <MenuItem value={'BENEFICIARY'}>Beneficiary</MenuItem>
                        <MenuItem value={'BENEFICIARY_CLASS'}>Beneficiary Class</MenuItem>
                        <MenuItem value={'CHAIRPERSON'}>Chairperson</MenuItem>
                        <MenuItem value={'CONTRIBUTOR'}>Contributor</MenuItem>
                        <MenuItem value={'DIRECTOR'}>Director</MenuItem>
                        <MenuItem value={'INTERMEDIARY'}>Intermediary</MenuItem>
                        <MenuItem value={'OWNER'}>Owner</MenuItem>
                        <MenuItem value={'POWER_OF_ATTORNEY'}>Power of Attorney</MenuItem>
                        <MenuItem value={'SECRETARY'}>Secretary</MenuItem>
                        <MenuItem value={'SOLICITOR'}>Solicator</MenuItem>
                        <MenuItem value={'SUPER'}>Super</MenuItem>
                        <MenuItem value={'TREASURER'}>Treasurer</MenuItem>
                        <MenuItem value={'TRUST'}>Trust</MenuItem>
                        <MenuItem value={'TRUSTEE'}>Trustee</MenuItem>
                        <MenuItem value={'TRUST_APPOINTER_PROTECTOR'}>Trust Appointer Protector</MenuItem>
                        <MenuItem value={'TRUST_CONTRIBUTOR'}>Trust Contributor</MenuItem>
                        <MenuItem value={'TRUST_EXECUTOR'}>Trust Executor</MenuItem>
                        <MenuItem value={'TRUST_SETTLOR'}>Trust Settlor</MenuItem>
                        <MenuItem value={'OTHER'}>Other (please specify)</MenuItem>
                    </Select>
                </FormControl>
                <TextField id="roles-other" label="'Other' Description" required/>
                <p>Only show "Other Description" if Other is selected</p>

            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <h4>Address</h4>
            </AccordionSummary>
            <AccordionDetails>
                <AddressComponent/>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <h4>Contact</h4>
            </AccordionSummary>
            <AccordionDetails>
                <TextField id="email" label="Email" required/>
                <TextField id="phone" label="Phone number" required/>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <h4>Tax Details</h4>
            </AccordionSummary>
            <AccordionDetails>
                <TextField id="taxNumber" label={isAu ? 'Tax File Number (TFN)' : 'Tax Identification Number (TIN)'}/>

                <CountryComponent label={'Country of Tax Residency'} required/>

                <FormControl sx={{minWidth: 200}} required>
                    <InputLabel
                        id="taxExemption-label">{isAu ? 'Tax File Number (TFN)' : 'Tax Identification Number (TIN)'} Exemption
                        Code</InputLabel>
                    <Select
                        labelId="taxExemption-label"
                        id="taxExemption-select"
                        value={[]}
                        label="exemption code"
                        onChange={() => {
                        }}
                    >
                        {isAu && (
                            <>
                                <MenuItem value={'ALPHABETIC_OR_MORE_THAN_11_CHARS'}>Alphabetic Or More Than 11
                                    Chars</MenuItem>
                                <MenuItem value={'TAX_RETURN_NOT_REQUIRED'}>Tax Return Not Required</MenuItem>
                                <MenuItem value={'AGED_UNDER_SIXTEEN'}>Aged Under Sixteen</MenuItem>
                                <MenuItem value={'NON_RESIDENT'}>Non-Resident</MenuItem>
                                <MenuItem value={'NORFOLK_ISLAND_RESIDENT'}>Norfolk Island Resident</MenuItem>
                                <MenuItem value={'NO_TFN_QUOTED'}>No TFN Quoted</MenuItem>
                                <MenuItem value={'OTHER_SOCIAL_SECURITY_BENEFIT'}>Other Social Security
                                    Benefit</MenuItem>
                                <MenuItem value={'PROVIDER_OF_CONSUMER_OR_BUSINESS_FINANCE'}>Provider Of Consumer or
                                    Business Finance</MenuItem>
                                <MenuItem value={'SOCIAL_SECURITY_OR_VET_PENSION'}>Social Secutiry or Vet
                                    Pension</MenuItem>
                            </>
                        )}

                        {!isAu && (
                            <>
                                <MenuItem value={'NOT_ISSUED'}>Not Issued</MenuItem>
                                <MenuItem value={'NOT_REQUIRED'}>Not Required</MenuItem>
                                <MenuItem value={'APPLIED_FOR'}>Applied For</MenuItem>
                                <MenuItem value={'UNOBTAINABLE'}>Unobtainable</MenuItem>
                            </>
                        )}
                    </Select>
                </FormControl>
                <p>Need to check these. Some sound like they should be inferred (e.g. Alphabetic or more than 11 Chars).
                    Also not sure whether to show AU or international list of exemptions. Hardcoded to AU list at the
                    moment.</p>
                
                <p>Country fields should only show for non-AU tax residents, not show how this is determined.</p>
                <CountryComponent label={'Country of Birth'} required/>
                <TextField id="townOfBirth" label="Town of Birth" required/>
            </AccordionDetails>
        </Accordion>

    </div>
}