import React, {FC} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {AdminDashboard} from './adminDashboard/AdminDashboard';
import {AuthenticatedShell} from './AuthenticatedShell';
import {ErrorBoundary} from './ErrorBoundary';
import {PublicShell} from './PublicShell';
import {IAuthenticationProvider} from '../auth/AuthenticationTypes';
import {useAuthentication} from '../hooks/useAuthentication';
import {useStore} from '../hooks';
import {Home} from './home/Home';
import {SignUp} from "./signUp/SignUp";
import {NotFound} from "./NotFound";
import {UserHome} from "./userHome/UserHome";
import {Disclaimer} from "./disclaimer/Disclaimer";
import {Backdrop, CircularProgress} from '@material-ui/core';

interface IProps {
    auth: IAuthenticationProvider;
}

export const AuthenticatedRoutes: FC<IProps> = function ({auth}) {
    const location = useLocation();
    const authState = useAuthentication(auth);
    const store = useStore();

    if (authState === 'signed-in' && store.state === 'unauthorized') {
        return <p>Unauthorised</p>;
    }

    if (authState === 'loading') {
        return <Backdrop  open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>;
    }

    if (authState === 'signed-in') {
        return (
            <AuthenticatedShell>
                <ErrorBoundary key={location.pathname}>
                    
                    <Disclaimer/>
                    
                    <Switch>
                        <Route exact path="/user/dashboard">
                            <UserHome/>
                        </Route>
                        <Route exact path="/admin">
                            <AdminDashboard/>
                        </Route>
                        <Route exact path="/user/support">
                            <>
                                <h1>Support</h1>
                                <p>
                                    Please contact us at <a href="mailto:info@suubee.com">info@suubee.com</a> for
                                    support.
                                </p>
                            </>
                        </Route>
                        <Route>
                            <h1>User area page not found</h1>
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </AuthenticatedShell>
        );
    }

    if (window.location.hash.indexOf('id_token') > -1) {
        window.location.href = '/user/dashboard';
    }

    return <Backdrop  open={true}>
        <CircularProgress color="inherit" />
    </Backdrop>;
};

export const PublicRoutes: FC = function () {
    const location = useLocation();

    return (
        <PublicShell>
            <ErrorBoundary key={location.pathname}>
                <Switch>
                    <Route exact path="/sign-up">
                        <SignUp/>
                    </Route>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route>
                        <NotFound/>
                    </Route>
                </Switch>
            </ErrorBoundary>
        </PublicShell>
    );
};
