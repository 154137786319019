import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {IRootStoreModel} from "./store/RootStoreModel";
import styles from './App.module.scss';
import {IAuthenticationProvider} from 'auth/AuthenticationTypes';
import {History} from 'history';
import {AuthenticatedRoutes, PublicRoutes} from "./components/Routes";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
// eslint-disable-next-line
import makeStyles from '@mui/styles/makeStyles';
import {Backdrop, CircularProgress} from "@material-ui/core";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const StoreContext = React.createContext<IRootStoreModel | undefined>(undefined);

interface IAppProps {
    history: History;
    store: IRootStoreModel;
    auth: IAuthenticationProvider;
}

const theme = createTheme();

export const App: React.FC<IAppProps> = function ({history, store, auth}) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div className={styles.root}>
                    <StoreContext.Provider value={store}>
                        <Router history={history}>
                            <Switch>
                                <Route path="/user">
                                    <Router history={history}>
                                        <AuthenticatedRoutes auth={auth}/>
                                    </Router>
                                </Route>
                                <Route exact path="/signedin">
                                    <Backdrop  open={true}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>;
                                </Route>
                                <Route path="/admin">
                                    <Router history={history}>
                                        <AuthenticatedRoutes auth={auth}/>
                                    </Router>
                                </Route>
                                <Route path="/">
                                    <Router history={history}>
                                        <PublicRoutes/>
                                    </Router>
                                </Route>
                            </Switch>
                        </Router>
                    </StoreContext.Provider>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};