import {flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {getEnv} from './util';

export const StockModel = types.model('StockModel', {
    code: types.string,
    dailyVolume: types.number,
    changeBasisPoints: types.maybeNull(types.number),
    lastPriceInCents: types.maybeNull(types.number),
    // matchPriceChangeBasisPoints: types.number,
    // matchPriceInCents: types.number,
    // matchVolume: types.number,
    weightingBasisPoints: types.number,
    movement: types.maybeNull(types.number),
    isCash: types.boolean,
    cashCurrency: types.maybeNull(types.string),
});

export const ListModel = types
    .model('ListModel', {
        name: types.identifier,
        isUSList: types.boolean,
        changeBasisPoints: types.number,
        cashAllocationBasisPoints: types.number
    });

export const ListDetailModel = types.model("ListDetailModel", {
    name: types.identifier,
    stocks: types.array(StockModel),
    changeBasisPoints: types.number
});

export const ListPerformanceDataItem = types.model("ListPerformanceDataItem", {
    date: types.string,
    changeBps: types.number
});

export const ListPerformanceModel = types.model("ListPerformanceModel", {
    name: types.identifier,
    data: types.array(ListPerformanceDataItem)
});

export const ListChangeItemModel = types.model("ListChangeItemModel", {
    date: types.string,
    changeBps: types.number,
    ticker: types.string,
    changeType: types.string
});
    
export const ListChangeModel = types.model("ListChangeModel", {
    name: types.identifier,
    changes: types.array(ListChangeItemModel)
});



export interface IListPerformanceModel extends Instance<typeof ListPerformanceModel> {
}

export interface IListModel extends Instance<typeof ListModel> {
}

export interface IListDetailModel extends Instance<typeof ListDetailModel> {
}

export interface IStockModel extends Instance<typeof StockModel> {
}

export interface IListChangeModel extends Instance<typeof ListChangeModel> {
}


export const ListsModel = types
    .model('ListsModel', {
        lists: types.optional(types.array(ListModel), []),
        selectedList: types.maybeNull(types.reference(ListModel))
    })
    .actions((self) => ({
        loadLists: flow(function* () {
            const {ajax} = getEnv(self);

            try {
                const result = yield ajax
                    .get('list/')
                    .json<IListModel[]>()
                    .then((values) => values.map((item) => ListModel.create(item)));
                self.lists.clear();
                result.forEach((i: IListModel) => self.lists.push(i));
            } catch (error) {
                console.log('error loadLists : ' + error);
            }
        }),
        loadListDetails: flow(function* (name: string) {
            const {ajax} = getEnv(self);

            try {
                const result = yield ajax
                    .get('list/' + name)
                    .json<IListDetailModel>()
                    .then((value) => ListDetailModel.create(value));
                return result;
            }
            catch (error) {
                console.log('error loadLists : ' + error);
            }
        }),
        loadListPerformance: flow(function* (name: string) {
            const {ajax} = getEnv(self);

            try {
                const result = yield ajax
                    .get('list/performance/' + name)
                    .json<IListPerformanceModel>()
                    .then((value) => getSnapshot(ListPerformanceModel.create(value)));
                return result;
            }
            catch (error) {
                console.log('error loadListPerformance : ' + error);
            }
        }),
        loadListChanges: flow(function* (name: string) {
            const {ajax} = getEnv(self);

            try {
                const result = yield ajax
                    .get('list/changes/' + name)
                    .json<IListChangeModel>()
                    .then((value) => getSnapshot(ListChangeModel.create(value)));
                return result;
            }
            catch (error) {
                console.log('error loadListChanges : ' + error);
            }
        }),
        selectList: (list : IListModel) => {
            self.selectedList = list;
        }
    }))
    .views((self) => ({}));
