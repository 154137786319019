import {FC} from "react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";

export const Banking: FC = () => {
    return <>
        <h3>Account Banking Details</h3>

        <FormControl sx={{minWidth: 200}}>
            <InputLabel
                id="cmtProvider-label">CMT Provider</InputLabel>
            <Select
                labelId="cmtProvider-label"
                id="cmtProvider-select"
                value={''}
                label="CMT Provider"
                onChange={() => {
                }}
            >
                        <MenuItem value={'ANZA'}>ANZA</MenuItem>
                        <MenuItem value={'DDH'}>DDH</MenuItem>
                        <MenuItem value={'MBLA '}>MBLA </MenuItem>
            </Select>
        </FormControl>
        <p>Not really sure what this is, will try and get a peek at OM's signup form.</p>
        
        <TextField id="bankingName" label="Name"/>
        <TextField id="bankingBsb" label="BSB"/>
        <TextField id="bankingAccountNumber" label="Account Number"/>
        <FormGroup>
            <FormControlLabel control={<Checkbox/>} label="Pay income from dividends to this account"/>
        </FormGroup>
    </>
}