import React, {FC, useState} from "react";
import Container from "@mui/material/Container";
import {Applicant} from "./Applicant";
import {Banking} from "./Banking";
import {Review} from "./Review";
import {Button, Step, StepLabel, Stepper} from "@mui/material";
import styles from './SignUp.module.scss';
import {Account} from "./Account";


function getStepsLabels() {
    return ['Applicant', 'Banking', 'Account', 'Review'];
}

export const SignUp: FC = () => {
    const [activeStep, setActiveStep] = useState(0);
    const stepsLabels = getStepsLabels();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <Applicant/>;
            case 1:
                return <Banking/>;
            case 2:
                return <Account/>;
            case 3:
                return <Review/>;
            default:
                return <p>Unknown Step</p>;
        }
    }

    return <Container maxWidth="md" component="main" className={styles.container}>
        <div>
            <Stepper activeStep={activeStep}>
                {stepsLabels.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <div>
                {activeStep === stepsLabels.length ? (
                    <div>
                        <h4>Success!</h4>
                        <p>Set expectations on how long this will take and how they will be contacted etc.</p>
                    </div>
                ) : (
                    <div>
                        <div className={styles.stepPlaceholder}>
                            {getStepContent(activeStep)}
                        </div>
                        <div className={styles.buttonContainer}>
                            <Button variant="contained" disabled={activeStep === 0} onClick={handleBack}>
                                Back
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                            >
                                {activeStep === stepsLabels.length - 1 ? 'Submit' : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </Container>
}