import {Cell, Pie, PieChart} from 'recharts';
import React, {FC} from "react";


export interface chartDataItem{
    name:string;
    value: number;
    colour: string;
}

export interface chartData{
    data: chartDataItem[]
    secondaryData?: chartDataItem[]
}


export const SubscriptionPieChart: FC<chartData> = React.memo((prop) =>{
    
        return (
                <PieChart width={400} height={400}>
                    <Pie
                        isAnimationActive={false}
                        data={prop.data}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label={({cx, cy, midAngle, innerRadius, outerRadius, value, index}: any) => {
                            const RADIAN = Math.PI / 180;
                            const radius = 25 + innerRadius + (outerRadius - innerRadius);
                            const x = cx + radius * Math.cos(-midAngle * RADIAN);
                            const y = cy + radius * Math.sin(-midAngle * RADIAN);

                            return (
                                <text
                                    x={x}
                                    y={y}
                                    fill={prop.data[index % prop.data.length].colour}
                                    textAnchor={x > cx ? "start" : "end"}
                                    dominantBaseline="central"
                                >
                                    {prop.data[index].name} ({value}%)
                                </text>
                            );
                        }}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {prop.data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={prop.data[index % prop.data.length].colour} />
                        ))}
                    </Pie>
                    {prop.secondaryData && prop.secondaryData.length > 0 &&(
                        <Pie data={prop.secondaryData} dataKey="value" cx="50%" cy="50%" innerRadius={90} outerRadius={100} fill="#82ca9d"  >
                            {prop.secondaryData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={prop.secondaryData![index % prop.secondaryData!.length].colour} />
                            ))}
                        </Pie>
                    )}

                </PieChart>
        );
})
