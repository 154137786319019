import * as React from "react";
import {FC, useEffect, useState} from "react";
import styles from "./SubscriptionChanges.module.scss";
import {Alert} from "@mui/material";
import {useStore} from "../../hooks";
import {listSubscription} from "../../store/AccountModel";
import {IListDetailModel} from "../../store/ListsModel";
import moment from "moment";
import {observer} from "mobx-react-lite";

interface ISubscriptionChangesProps {
    tabValue: number;
    onTabValueChange: (value: number) => void;
}

interface IBlendedPortfolioModelRecord {
    securityCode: string,
    targetWeightingBps: number
    lists: string[],
}

interface IChange {
    securityCode: string,
    changeBps: number,
    lists: string[],
}

export const SubscriptionChanges: FC<ISubscriptionChangesProps> = observer(({tabValue, onTabValueChange}) => {

    const {accountStore, listsStore} = useStore();
    const [show, setShow] = useState(false);
    const [lastSyncedPortfolio, setLastSyncedPortfolio] = useState<IBlendedPortfolioModelRecord[] | undefined>(undefined);
    const [latestPortfolio, setLatestPortfolio] = useState<IBlendedPortfolioModelRecord[] | undefined>(undefined);
    const [distinctLists, setDistinctLists] = useState<string>('');
    const [added, setAdded] = useState<IChange[]>([]);
    const [removed, setRemoved] = useState<IChange[]>([]);
    const [unssubscribed, setUnsubscribed] = useState<IChange[]>([]);
    const [showAddedDetails, setShowAddedDetails] = useState(false);
    const [showRemovedDetails, setShowRemovedDetails] = useState(false);
    const [userClosed, setUserClosed] = useState(false);
    const [combinedSubscriptions, setCombinedSubscriptions] = useState<listSubscription[]>([]);

    useEffect(() => {
        accountStore.loadAccount().then(() => {
            const current = JSON.parse(accountStore.account?.lastRebalancedBlendedPortfolio ?? '[]') as IBlendedPortfolioModelRecord[];
            const currentUs = JSON.parse(accountStore.account?.lastUsRebalancedBlendedPortfolio ?? '[]') as IBlendedPortfolioModelRecord[];
            current.push(...currentUs);
            setLastSyncedPortfolio(current);
        });
    }, [accountStore, accountStore.lastRebalancedTimestamp]);

    useEffect(() => {

        let getListsPromises: Promise<any>[] = [];

        let subscriptions: listSubscription[] = [];

        if (accountStore.account?.currentSubscription) {
            const auSubscriptions = (JSON.parse(accountStore.account!.currentSubscription.subscriptionComposition) as listSubscription[]).filter((subscription) => subscription.Allocation > 0);


            auSubscriptions.forEach((subscription) => {
                getListsPromises.push(listsStore.loadListDetails(subscription.List));
            });

            subscriptions.push(...auSubscriptions);
        }

        if (accountStore.account?.currentUsSubscription) {
            const usSubscriptions = (JSON.parse(accountStore.account!.currentUsSubscription.subscriptionComposition) as listSubscription[]).filter((subscription) => subscription.Allocation > 0);

            usSubscriptions.forEach((subscription) => {
                getListsPromises.push(listsStore.loadListDetails(subscription.List));
            });

            subscriptions.push(...usSubscriptions);
        }

        setCombinedSubscriptions(subscriptions);

        Promise.all(getListsPromises).then((listDetails: IListDetailModel[]) => {
                let blendedModel: IBlendedPortfolioModelRecord[] = [];

                subscriptions.forEach((listSubscription: listSubscription) => {
                    const listAllocation = listSubscription.Allocation;
                    const listName = listSubscription.List;

                    const listDetail = listDetails
                        .find((listDetail) => listDetail.name === listName);

                    if (!listDetail) {
                        throw new Error(`List Details not found: ${listName}`);
                    }

                    listDetail.stocks.filter(s => !s.isCash).forEach((stock) => {

                        if (stock.lastPriceInCents === null || stock.lastPriceInCents === 0) {
                            console.log(`Stock has no last price - ${stock.code} - skipping`);
                            return;
                        }

                        let listAllocationPercentage = listAllocation / 100;
                        let stockAllocationPercentage = stock.weightingBasisPoints / 100 / 100;

                        const doesRebalancerRecordExist: boolean = blendedModel.some((rebalanceRecord) => rebalanceRecord.securityCode === stock.code);

                        // target weighting to two decimal places
                        if (!doesRebalancerRecordExist) {
                            blendedModel.push({
                                securityCode: stock.code,
                                lists: [listName],
                                targetWeightingBps: listAllocationPercentage * stockAllocationPercentage
                            });
                        } else {
                            const existingRecordIndex = blendedModel.findIndex((rebalanceRecord) => rebalanceRecord.securityCode === stock.code);
                            const existingRecord = blendedModel[existingRecordIndex];
                            blendedModel.splice(existingRecordIndex, 1);
                            existingRecord.targetWeightingBps = existingRecord.targetWeightingBps + (listAllocationPercentage * stockAllocationPercentage);
                            existingRecord.lists.push(listName);
                            blendedModel.push(existingRecord);
                        }
                    });
                });
                setLatestPortfolio(blendedModel);
            }
        );


    }, [accountStore.account, accountStore.lastRebalancedTimestamp, listsStore]);

    useEffect(() => {
        let distinctLists: string[] = [];

        distinctLists = combinedSubscriptions.map((subscription) => subscription.List);

        if (distinctLists.length > 1) {
            let last = distinctLists.pop();
            let result = `${distinctLists.join(", ")} and ${last}`;
            setDistinctLists(result);
        } else {
            setDistinctLists(distinctLists.length > 0 ? distinctLists[0] : '');
        }
    }, [combinedSubscriptions, accountStore.lastRebalancedTimestamp]);


    useEffect(() => {

        if (lastSyncedPortfolio && latestPortfolio) {
            let beforeMap = new Map(lastSyncedPortfolio.map(item => [item.securityCode, item.lists]));
            let nowMap = new Map(latestPortfolio.map(item => [item.securityCode, item.lists]));

            let added: IChange[] = [];
            let removed: IChange[] = [];
            let unsubscribed: IChange[] = [];

            nowMap.forEach((targetLists, ticker) => {
                if (!beforeMap.has(ticker)) {
                    added.push({securityCode: ticker, lists: targetLists, changeBps: 0});
                } else {
                    // let beforeLists = beforeMap.get(key);
                    targetLists.forEach(list => {
                        if (!lastSyncedPortfolio.filter(lsp => lsp.lists.includes(list))) {
                            added.push({securityCode: ticker, lists: [list], changeBps: 0});
                        }
                    });
                }
            });

            beforeMap.forEach((beforeLists, ticker) => { // look for removed names
                if (!nowMap.has(ticker)) { // removed from all lists
                    removed.push({securityCode: ticker, lists: beforeLists, changeBps: 0});
                } else { // check if removed from specific list
                    beforeLists.forEach(list => {
                        var tickerLists = nowMap.get(ticker);
                        if (!tickerLists || !tickerLists.includes(list)) {
                            removed.push({securityCode: ticker, lists: [list], changeBps: 0});
                        }
                    });
                }
            });


            let currentlySubscribedLists: string[] = [];
            currentlySubscribedLists = combinedSubscriptions.map((subscription) => subscription.List);

            removed.forEach(r => {
                if (r.lists.some(l => !currentlySubscribedLists.includes(l))) {
                    unsubscribed.push(r);
                    removed = removed.filter(removed => removed.securityCode !== r.securityCode);
                }
            })

            setUnsubscribed(unsubscribed);
            setAdded(added);
            setRemoved(removed);
            setShow(true);
        }

    }, [lastSyncedPortfolio, latestPortfolio, accountStore.lastRebalancedTimestamp]);

    return (
        <>
            {show && !userClosed && (
                <div className={styles.container}>
                    <Alert severity="info" className={styles.alert} onClose={() => {
                        setUserClosed(true)
                    }}>

                        {distinctLists.length > 0 && (
                            <p>
                                You are subscribed to {distinctLists}.
                            </p>
                        )}

                        {distinctLists.length === 0 && (
                            <p>
                                You are not currently subscribed to any lists.
                            </p>
                        )}

                        <p>
                            {accountStore.account?.lastRebalancedTimestamp && (
                                <>
                                    Your last download of your <button className={styles.linkButton}
                                                                       onClick={() => {
                                                                           onTabValueChange(1)
                                                                       }}>AUS rebalance
                                    file</button> was {moment(accountStore.lastRebalancedTimestamp ?? accountStore.account?.lastRebalancedTimestamp).fromNow()}.
                                </>
                            )}
                            {!accountStore.account?.lastRebalancedTimestamp && (
                                <>
                                    You have not yet downloaded your <button className={styles.linkButton}
                                                                             onClick={() => {
                                                                                 onTabValueChange(1)
                                                                             }}>AUS rebalance file</button>.
                                </>
                            )}
                        </p>

                        <p>
                            {accountStore.account?.lastUsRebalancedTimestamp && (
                                <>
                                    Your last download of your <button className={styles.linkButton}
                                                                          onClick={() => {
                                                                              onTabValueChange(1.1)
                                                                          }}>US rebalance file</button> was {moment(accountStore.lastUsRebalancedTimestamp ?? accountStore.account?.lastUsRebalancedTimestamp).fromNow()}.
                                </>
                            )}
                            {!accountStore.account?.lastUsRebalancedTimestamp && (
                                <>
                                    You have not yet downloaded your <button className={styles.linkButton}
                                                                             onClick={() => {
                                                                                 onTabValueChange(1.1)
                                                                             }}>US rebalance file</button>.
                                </>
                            )}
                        </p>

                        {accountStore.account?.lastRebalancedTimestamp && (
                            <>
                                <p>
                                    {added.length > 0 && (
                                        <>
                                            Since then, {added.length} names have been added&nbsp;

                                            <button className={styles.linkButton} onClick={(e) => {
                                                e.preventDefault();
                                                setShowAddedDetails(!showAddedDetails)
                                            }}>
                                                {showAddedDetails ? '(hide)' : '(show)'}
                                            </button>
                                        </>
                                    )}

                                    {added.length === 0 && (
                                        <>
                                            Since then, no names have been added
                                        </>
                                    )}

                                    &nbsp;and&nbsp;

                                    {(removed.length + unssubscribed.length > 0) && (
                                        <>
                                            {removed.length + unssubscribed.length} names were removed&nbsp;
                                            {/* Add a "Show More" text link for the removed stocks */}
                                            <button className={styles.linkButton} onClick={(e) => {
                                                e.preventDefault();
                                                setShowRemovedDetails(!showRemovedDetails)
                                            }}>
                                                {showRemovedDetails ? '(hide)' : '(show)'}
                                            </button>
                                            .
                                        </>
                                    )}

                                    {(removed.length + unssubscribed.length === 0) && (
                                        <>
                                            no names were removed.
                                        </>
                                    )}
                                </p>

                                {/* Conditionally render the added details */}
                                {showAddedDetails && (
                                    <>
                                        {Array.from(added.reduce((map, stock) => {
                                            stock.lists.forEach(list => {
                                                if (!map.has(list)) map.set(list, []);
                                                let stocks = map.get(list);
                                                if (stocks) {
                                                    stocks.push(stock.securityCode);
                                                }
                                            });
                                            return map;
                                        }, new Map<string, string[]>())).map(([list, stocks]) => (
                                            <p className={styles.smaller} key={list}>{stocks.join(', ')} were added
                                                to {list}.</p>
                                        ))}
                                    </>
                                )}

                                {/* Conditionally render the removed details */}
                                {showRemovedDetails && (
                                    <>
                                        {Array.from(removed.reduce((map, stock) => {
                                            stock.lists.forEach(list => {
                                                if (!map.has(list)) map.set(list, []);
                                                let stocks = map.get(list);
                                                if (stocks) {
                                                    stocks.push(stock.securityCode);
                                                }
                                            });
                                            return map;
                                        }, new Map<string, string[]>())).map(([list, stocks]) => (
                                            <p className={styles.smaller} key={list}>{stocks.join(', ')} were removed
                                                from {list}.</p>
                                        ))}

                                        {Array.from(unssubscribed.reduce((map, stock) => {
                                            stock.lists.forEach(list => {
                                                if (!map.has(list)) map.set(list, []);
                                                let stocks = map.get(list);
                                                if (stocks) {
                                                    stocks.push(stock.securityCode);
                                                }
                                            });
                                            return map;
                                        }, new Map<string, string[]>())).map(([list, stocks]) => (
                                            <p className={styles.smaller} key={list}>{stocks.join(', ')} were removed
                                                as you're no longer subscribed to {list}.</p>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </Alert>
                </div>
            )}
        </>
    );
});
