import {observer} from "mobx-react-lite";
import styles from "./Rebalancer.module.scss";
import {useStore} from "../../hooks";
import * as React from "react";
import {useEffect, useState} from "react";
import {Skeleton, Tab, TableBody, TableHead, TextField} from "@mui/material";
import {IClientAccountDetails, IHoldingModel, listSubscription} from "../../store/AccountModel";
import {IListDetailModel} from "../../store/ListsModel";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {ORDER_COMMISSION_PERCENTAGE} from "../../store/util";
import { CSVLink } from "react-csv";


interface IRebalanceRecord {
    securityCode: string,
    targetPositionSize: number,
    currentPositionSize: number,
    lists: string[],
    
    chosenOrderSize: number,
    deviationPercentage: number,
    includeInOrder: boolean,

    orderType: 'MKT' | 'LMT',
    lastPriceInCents: number,
}


export const Rebalancer = observer(() => {

    const {accountStore, listsStore} = useStore();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [nlvInCents, setNlvInCents] = useState<number | null>(null);
    const [holdings, setHoldings] = useState<IHoldingModel[] | null>(null);
    const [listSubscriptions, setListSubscriptions] = useState<listSubscription[] | null>(null);
    const [listDetails, setListDetails] = useState<IListDetailModel[] | null>(null);
    const [rebalanceRecords, setRebalanceRecords] = useState<IRebalanceRecord[]>([]);
    const [deviationThreshold, setDeviationThreshold] = useState<number>(5);
    const [csvData, setCsvData] = useState<string[][]>([]);

    useEffect(() => {
        setIsLoading(true);

        const loadAccountAndAllListDetailsPromise = accountStore.loadAccount().then(() => {
            if (accountStore.account?.currentSubscription) {
                setIsLoading(true);

                const subscriptions = JSON.parse(accountStore.account.currentSubscription.subscriptionComposition) as listSubscription[];
                setListSubscriptions(subscriptions);

                let getListsPromises: Promise<any>[] = [];

                subscriptions.filter((subscription) => subscription.Allocation > 0).forEach((subscription) => {
                    getListsPromises.push(listsStore.loadListDetails(subscription.List));
                });

                return Promise.all(getListsPromises).then((listResults: IListDetailModel[]) => {
                    setListDetails(listResults);
                });
            }
        });

        const loadHoldingsPromise = accountStore.loadHoldings().then((accountHoldings) => {
            setHoldings(accountHoldings);
        });

        const loadClientAccountDetailsPromise = accountStore.loadClientAccountDetails().then((accountDetails: IClientAccountDetails) => {
            setNlvInCents(accountDetails.netLiquidation.amountInCents);
        });

        Promise.all([loadAccountAndAllListDetailsPromise, loadHoldingsPromise, loadClientAccountDetailsPromise]).then(() => {

        }).finally(() => {
            setIsLoading(false);
        });
    }, [setIsLoading, accountStore]);


    useEffect(() => {
        if (nlvInCents === null || holdings === null || listDetails === null || listSubscriptions === null) {
            return;
        }

        let rebalanceRecords: IRebalanceRecord[] = [];

        if (nlvInCents === null) {
            throw new Error("NLV is null");
        }

        listSubscriptions.filter(sub => sub.Allocation > 0).forEach((listSubscription: listSubscription) => {

            const listAllocation = listSubscription.Allocation;
            const listName = listSubscription.List;

            const listDetail = listDetails
                .find((listDetail) => listDetail.name === listName);

            if (!listDetail) {
                throw new Error(`List Details not found: ${listName}`);
            }

            listDetail.stocks.filter(s => !s.isCash).forEach((stock) => {

                if (stock.lastPriceInCents === null || stock.lastPriceInCents === 0) {
                    throw new Error(`Stock has no last price - ${stock.code} `);
                }

                let listAllocationPercentage = listAllocation / 100;
                let stockAllocationPercentage = stock.weightingBasisPoints / 100 / 100;
                const targetPositionSize = Math.floor(
                    (listAllocationPercentage * stockAllocationPercentage * nlvInCents * ((100 - ORDER_COMMISSION_PERCENTAGE)/100))
                    / stock.lastPriceInCents);
                const currentPositionSize = holdings.find((holding) => holding.securityCode === stock.code)?.totalHolding ?? 0;
                const chosenOrderSize = targetPositionSize - currentPositionSize; // default rebalancing; user can change

                const doesRebalancerRecordExist: boolean = rebalanceRecords.some((rebalanceRecord) => rebalanceRecord.securityCode === stock.code);

                if (!doesRebalancerRecordExist) {
                    rebalanceRecords.push({
                        securityCode: stock.code,
                        targetPositionSize: targetPositionSize,
                        currentPositionSize: currentPositionSize,
                        lists: [listName],
                        chosenOrderSize: chosenOrderSize,
                        deviationPercentage: Math.abs(100 - Math.abs(currentPositionSize/targetPositionSize * 100 * ((100 - ORDER_COMMISSION_PERCENTAGE)/100))),
                        includeInOrder: true,
                        lastPriceInCents: stock.lastPriceInCents,
                        orderType: 'LMT'
                    });
                } else {
                    const existingRecordIndex = rebalanceRecords.findIndex((rebalanceRecord) => rebalanceRecord.securityCode === stock.code);
                    const existingRecord = rebalanceRecords[existingRecordIndex];
                    rebalanceRecords.splice(existingRecordIndex, 1);

                    existingRecord.targetPositionSize = existingRecord.targetPositionSize + targetPositionSize;
                    existingRecord.currentPositionSize = existingRecord.currentPositionSize + currentPositionSize;
                    existingRecord.chosenOrderSize = existingRecord.chosenOrderSize + chosenOrderSize;
                    existingRecord.deviationPercentage = Math.abs(100 - Math.abs(existingRecord.currentPositionSize/existingRecord.targetPositionSize * 100));
                    existingRecord.lists.push(listName);

                    rebalanceRecords.push(existingRecord);
                }
            });
        });

        //Sell down any existing holdings not held due to subscription
        const holdingsNotInASubscribedList = holdings
            .filter((holding) => {
                return !rebalanceRecords
                    .some((rebalanceRecord) => rebalanceRecord.securityCode === holding.securityCode)
            });

        holdingsNotInASubscribedList.forEach((holding) => {
            rebalanceRecords.push({
                securityCode: holding.securityCode,
                targetPositionSize: 0,
                currentPositionSize: holding.totalHolding,
                lists: [],
                chosenOrderSize: -holding.totalHolding,
                deviationPercentage: 100,
                includeInOrder: true,
                lastPriceInCents: 0,
                orderType: 'MKT'
            });
        });

        setRebalanceRecords(rebalanceRecords.filter(r => r.chosenOrderSize !== 0));

    }, [holdings, nlvInCents, listDetails, listSubscriptions]);
    
    
    const generateCsvData = () => {
        let data : string[][] = [];
        data.push(["Action", "Quantity", "Symbol", "SecType", "Exchange", "Currency", "TimeInForce", "OrderType", "LmtPrice","BasketTag","Account","OrderRef"]);
        
        rebalanceRecords.forEach((record) => {
            if (record.includeInOrder && record.chosenOrderSize !== 0 && record.deviationPercentage > deviationThreshold) {
                let securityCodeTokens = record.securityCode.split(".");
                const securitySymbol = securityCodeTokens[0];
                const exchange = securityCodeTokens[1];
                data.push([record.chosenOrderSize > 0?"BUY":"SELL", Math.abs(record.chosenOrderSize).toString(), securitySymbol, "STK", exchange, exchange === 'ASX'? 'AUD':'USD', "DAY", record.orderType, record.orderType === "LMT"? (record.lastPriceInCents / 100).toFixed(2): "", "Basket", accountStore.account!.brokerageAccount!, "Basket"]);
            }
        });
        
        setCsvData(data);
    };

    return <div className={styles.container}>
        {isLoading && (
            <Skeleton variant={"text"} height={400}/>
        )}

        {!isLoading && (
            <>
                <div>
                    <TextField
                        label="Deviation From Target Threshold %"
                        type="number"
                        value={deviationThreshold}
                        onChange={(e) => {
                            let rangeBoundValue = (parseInt(e.target.value) < 0) ? 0 : (parseInt(e.target.value) > 100 ? 100 : parseInt(e.target.value));
                            setDeviationThreshold(rangeBoundValue);
                        }}
                        variant="outlined"
                        margin="normal"
                    />
                </div>
                
                <div className={styles.tableContainer}>
                    <Table size="small">
                        <TableHead className={styles.rowHeader}>
                            <TableRow>
                                <TableCell>List</TableCell>
                                <TableCell>Ticker</TableCell>
                                <TableCell>Current<br/>Position</TableCell>
                                <TableCell>Target<br/>Position</TableCell>
                                <TableCell>Order Size</TableCell>
                                <TableCell>Include</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rebalanceRecords).map((rebalanceRecord: IRebalanceRecord, key) => (
                                <TableRow key={key}>
                                    <TableCell>{rebalanceRecord.lists.join(", ")}</TableCell>
                                    <TableCell>{rebalanceRecord.securityCode}</TableCell>
                                    <TableCell align="right">{rebalanceRecord.currentPositionSize}</TableCell>
                                    <TableCell align="right">{rebalanceRecord.targetPositionSize}</TableCell>
                                    <TableCell>
                                        <TextField size={"small"} type={"number"}
                                                   value={rebalanceRecord.chosenOrderSize} onChange={(e) => {
                                            const newOrderSize = parseInt(e.target.value);
                                            const newRebalanceRecords = [...rebalanceRecords];
                                            newRebalanceRecords[key].chosenOrderSize = newOrderSize;
                                            setRebalanceRecords(newRebalanceRecords);
                                        }}/>
                                    </TableCell>
                                    <TableCell>
                                        <input type={"checkbox"} disabled={rebalanceRecord.deviationPercentage <= deviationThreshold} checked={rebalanceRecord.includeInOrder && rebalanceRecord.deviationPercentage >= deviationThreshold}
                                               onChange={(e) => {
                                                   const newIncludeInOrder = e.target.checked;
                                                   const newRebalanceRecords = [...rebalanceRecords];
                                                   newRebalanceRecords[key].includeInOrder = newIncludeInOrder;
                                                   setRebalanceRecords(newRebalanceRecords);
                                               }}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>

                <div className={styles.footer}>
                    <CSVLink data={csvData} onClick={generateCsvData}  filename={"IBOrder.csv"}>
                        Download IB Basket Order File
                    </CSVLink>
                </div>


                {/*{JSON.stringify(rebalanceRecords)}*/}
                {/*<br/><br/>*/}
                {/*NLV (cents): {nlvInCents}*/}
                {/*<br/><br/>*/}
                {/*Account: {JSON.stringify(accountStore.account)}*/}
                {/*<br/><br/>*/}
                {/*Holdings: {JSON.stringify(holdings)}*/}
                {/*<br/><br/>*/}
                {/*List Subscriptions: {JSON.stringify(listSubscriptions)}*/}
                {/*<br/><br/>*/}
                {/*List Details: {JSON.stringify(listDetails)}*/}
            </>
        )}
    </div>
});
