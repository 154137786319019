import Container from '@mui/material/Container';
import {FC} from 'react';
import {Link} from "react-router-dom";

export const NotFound: FC = function () {
    return (
        <Container maxWidth="md" component="main">
            <h1>The page you were looking for cannot be found ¯\_(ツ)_/¯</h1>
            <p>Return <Link to="/">home</Link></p>
        </Container>
    );
};
