import * as React from 'react';
import {useEffect, useState} from 'react';
import {useStore} from "../../hooks";
import {IListDetailModel} from "../../store/ListsModel";
import styles from './ListDetails.module.scss';
import {Skeleton, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {observer} from 'mobx-react-lite';
import Title from "../Title";

export default observer(function ListDetails() {

    const {listsStore: listStore} = useStore();

    const [listdetails, setListdetails] = useState<IListDetailModel | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);


    useEffect(() => {
        if (listStore.selectedList) {
            setIsLoading(true);
            listStore.loadListDetails(listStore.selectedList.name).then((details: IListDetailModel) => {
                setListdetails(details);
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            setListdetails(null);
        }
    }, [listStore, setListdetails, listStore.selectedList])

    return (
        <div className={styles.container}>
            {isLoading ? (
                <>
                    <Skeleton variant={"rectangular"} height={200}/>
                </>
            ) : (
                <>
                    {listdetails && (
                        <>
                            <Title>{listStore.selectedList?.name} - Details</Title>
                            <div className={styles.tableContainer}>
                            <Table size="small">
                                <TableHead className={styles.rowHeader}>
                                    <TableRow>
                                        <TableCell>Ticker</TableCell>
                                        <TableCell align="right">Price ($)</TableCell>
                                        <TableCell align="right">Change (%)</TableCell>
                                        <TableCell align="right">Movement ($)</TableCell>
                                        <TableCell align="right">Weighting (%)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={styles.tableBody}>
                                    {(listdetails!.stocks || []).map((stock, key) => (
                                        <TableRow key={key}>
                                            {stock.isCash && (
                                                <>
                                                    <TableCell>{stock.code}</TableCell>
                                                    <TableCell
                                                        align="right"></TableCell>
                                                    <TableCell
                                                        align="right"></TableCell>
                                                    <TableCell
                                                        align="right"></TableCell>
                                                    <TableCell
                                                        align="right">{(stock.weightingBasisPoints / 100).toFixed(0)}</TableCell>
                                                </>
                                            )}

                                            {!stock.isCash && (
                                                <>
                                                    <TableCell>{stock.code}</TableCell>
                                                    <TableCell
                                                        align="right">{stock.lastPriceInCents != null ? (stock.lastPriceInCents / 100).toFixed(2): ""}</TableCell>
                                                    <TableCell
                                                        align="right">{stock.changeBasisPoints != null ? (stock.changeBasisPoints / 100).toFixed(2): ""}</TableCell>
                                                    <TableCell
                                                        align="right">{(stock.movement)}</TableCell>
                                                    <TableCell
                                                        align="right">{(stock.weightingBasisPoints / 100).toFixed(2)}</TableCell>
                                                </>
                                            )}

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            </div>
                        </>
                        
                    )}
                </>
            )}


        </div>
    );
});