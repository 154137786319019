import {FC} from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import {ConfirmProvider} from 'material-ui-confirm';
import {Link as RouterLink,} from "react-router-dom";
import styles from './PubllicShell.module.scss';


function Copyright(props: any) {
    return (
        <Typography variant="body2" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://suubee.com">
                Suubee Group
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}<br/>
            <span>Authorised Representative No. 1262749 of Sanlam Private Wealth Pty Ltd which holds Australian Financial Services number 337927</span>
        </Typography>
    );
}

const footers = [
    {
        title: 'Company',
        description: ['Team', 'Contact us'],
    },
    {
        title: 'Resources',
        description: ['Resource'],
    },
    {
        title: 'Legal',
        description: ['Privacy policy', 'Terms of use'],
    },
];

export const PublicShell: FC = function ({children}) {
    return (
        <div className={styles.container}>
            <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
            <CssBaseline/>
            <AppBar className={styles.appBar}
                    position="static"
                    elevation={0}
                    sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
            >
                <Toolbar sx={{flexWrap: 'wrap'}}>
                    <Typography noWrap sx={{flexGrow: 1}}>
                        <Button className={styles.suubeeLogo} component={RouterLink} to="/" variant={"text"}
                                sx={{my: 1, mx: 1.5}}>
                            <span><img src="suubee-circle.png"/> <strong>suubee</strong></span>&nbsp;<span
                            className={styles.grey}>Portfolios</span>
                        </Button>
                    </Typography>
                    <nav>
                        {/*<Link*/}
                        {/*    component={RouterLink}*/}
                        {/*    variant="button"*/}
                        {/*    color="text.primary"*/}
                        {/*    to="/sign-up"*/}
                        {/*    sx={{my: 1, mx: 1.5}}*/}
                        {/*>*/}
                        {/*    Sign Up*/}
                        {/*</Link>*/}
                    </nav>
                    <Button className={styles.loginButton} component={RouterLink} to="/user/dashboard"
                            variant={"contained"} sx={{my: 1, mx: 1.5}}>
                        Login
                    </Button>
                </Toolbar>
            </AppBar>
            {/* Hero unit */}
            <ConfirmProvider>
                <div className={styles.publicShellContainer}>
                    {children}
                </div>
            </ConfirmProvider>
            {/* Footer */}
            <Container
                className={styles.footer}
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 8,
                    py: [3, 6],
                }}
            >
                <Grid container spacing={4} justifyContent="space-evenly">
                    {footers.map((footer) => (
                        <Grid item xs={6} sm={3} key={footer.title}>
                            <Typography variant="h6" className={styles.footerTitle}  gutterBottom>
                                {footer.title}
                            </Typography>
                            <ul>
                                {footer.description.map((item) => (
                                    <li key={item}>
                                        <Link href="#" className={styles.footerItem} variant="subtitle1">
                                            {item}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Copyright sx={{mt: 5}}/>
            </Container>
            {/* End footer */}
        </div>
    );
}





