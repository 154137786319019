import { useCallback, useEffect, useState } from 'react';
import { AuthenticationProviderState, IAuthenticationProvider } from '../auth/AuthenticationTypes';

export function useAuthentication(provider: IAuthenticationProvider): AuthenticationProviderState {
  const [state, setState] = useState<AuthenticationProviderState>(provider.initialState);
  const updateState = useCallback(async () => {
    return await provider.signIn();
  }, [provider]);

  useEffect(() => {
    updateState().then(setState);
  }, [updateState]);

  return state;
}
