import React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import {useStore} from '../hooks';
import {Link as RouterLink} from "react-router-dom";
import {ConfirmProvider} from "material-ui-confirm";
import styles from './AuthenticatedShell.module.scss';

function Copyright(props: any) {
    return (
        <Typography variant="body2" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://suubee.com">
                Suubee Group
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}<br/>
            <span>Authorised Representative No. 1262749 of Sanlam Private Wealth Pty Ltd which holds Australian Financial Services number 337927</span>
        </Typography>
    );
}

export const AuthenticatedShell: React.FC = function ({children}) {
    const store = useStore();
    
    const signOut = () => {
        store.security.signOut();
    };
    
    return (
        <div className={styles.container}>
            <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
            <CssBaseline/>
            <AppBar
                className={styles.appBar}
                position="static"
                elevation={0}
                sx={{borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
            >
                <Toolbar sx={{flexWrap: 'wrap'}}>
                    <Typography noWrap sx={{flexGrow: 1}}>
                        <Button className={styles.suubeeLogo} component={RouterLink} to="/user/dashboard" variant={"text"} sx={{my: 1, mx: 1.5}}>
                            <span><img src="suubee-circle.png"/> <strong>suubee</strong></span>&nbsp;<span
                            className={styles.grey}>Portfolios</span>
                        </Button>
                    </Typography>
                    <nav>
                        <Link
                            component={RouterLink}
                            variant="button"
                            to="/user/desk"
                            sx={{my: 1, mx: 1.5}}
                        >
                            Trade Desk
                        </Link>
                        <Link
                            component={RouterLink}
                            variant="button"
                            to="/user/support"
                            sx={{my: 1, mx: 1.5}}
                        >
                            Support
                        </Link>
                    </nav>
                    <Button className={styles.logout} variant="contained" onClick={signOut}>
                        {store.security.getSignedInName()} - logout
                    </Button>
                </Toolbar>
            </AppBar>
            {/* Hero unit */}
            <ConfirmProvider>
                <Container component="main">
                    {children}
                </Container>
            </ConfirmProvider>
            {/* Footer */}
            <Container
                maxWidth="md"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    mt: 2,
                    py: [3, 6],
                }}
            >
                <Copyright sx={{mt: 5}}/>
            </Container>
            {/* End footer */}
        </div>
    );
};
