import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styles from './AdminDashboard.module.scss'
import {useStore} from "../../hooks";
import {IAccountModel, INewClientModel, SubscriptionCompositionType} from "../../store/AccountModel";
import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardActions,
    CardContent, FormControl,
    FormGroup,
    Grid,
    InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField
} from '@mui/material';
import moment from 'moment';
import _ from "lodash";

export const AdminDashboard: FC = observer(function () {

    const {accountStore} = useStore();
    const [accounts, setAccounts] = useState<IAccountModel[]>([]);
    const [reload, setReload] = useState<boolean>(true);
    const [allButtonsDisabled, setAllButtonsDisabled] = useState<boolean>(false);

    const [inviteEmail, setInviteEmail] = useState<string>('');
    const [inviteName, setInviteName] = useState<string>('');
    const [inviteBrokerageAccount, setInviteBrokerageAccount] = useState<string>('');
    const [inviteAccountType, setInviteAccountType] = useState<string>('');
    
    const [newClient, setNewClient] = useState<INewClientModel|null>(null)

    useEffect(() => {
        if (reload) {
            accountStore.loadAccountsWithPendingSubscription().then((accounts) => {
                setAccounts(accounts);
            }).catch((error) => {
                console.log(error);
            });
            setReload(false);
        }

    }, [accountStore, reload])

    const handleMarkAsComplete = (requestId: number) => {
        setAllButtonsDisabled(true);
        accountStore.markSubscriptionChangeRequestAsComplete(requestId).then(() => {
            setReload(true);
        }).finally(() => {
            setAllButtonsDisabled(false);
        });
    }
    
    const handleReject = (requestId : number) => {
        setAllButtonsDisabled(true);
        accountStore.markSubscriptionChangeRequestAsRejected(requestId).then(() => {
            setReload(true);
        }).finally(() => {
            setAllButtonsDisabled(false);
        });
    }

    const handleSendInvite = () => {
        setNewClient(null);
        
        accountStore.inviteNewClient(inviteEmail, inviteName, inviteAccountType === "Retail"? "0" : inviteBrokerageAccount, inviteAccountType).then((newClient: INewClientModel) => {
            setNewClient(newClient);
            setInviteEmail('');
            setInviteName('');
            setInviteBrokerageAccount('');
            setInviteAccountType('');
        });
    }

    return (
        <div className={styles.container}>
            <Grid container>
                <Grid item sm={12} md={12}>
                    <h2>Invite new client</h2>
                    <FormGroup className={styles.inviteform}>
                        <p>Submitting this form will generate a special invite to join this app. The customer is not automatically emailed from this system. You will need to copy the link and give it to the customer. 
                            For 708's this will also <u>give
                            them access to the provided IBKR account's data</u>.</p>
                        <TextField className={styles.textfield} required id="invite-email" label="Email"
                                   value={inviteEmail}
                                   onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                       setInviteEmail(event.target.value)
                                   }}/>
                        <TextField className={styles.textfield} required id="invite-name" label="Name"
                                   value={inviteName} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setInviteName(event.target.value)
                        }}/>
                        <FormControl className={styles.textfield}>
                            <InputLabel id="label-accountype">Account Type *</InputLabel>
                            <Select
                                labelId="label-accountype"
                                id="sel-accountype"
                                value={inviteAccountType}
                                label={"Account Type"}
                                required
                                onChange={ (event: any) => { setInviteAccountType(event.target.value) }}
                            >
                                <MenuItem className={styles.selectorSelect} value={"Retail"}>Retail</MenuItem>
                                <MenuItem className={styles.selectorSelect} value={"s708"}>s708</MenuItem>
                            </Select>
                        </FormControl>
                        {inviteAccountType !== "Retail" && (
                            <TextField className={styles.textfield} required={inviteAccountType !== "Retail" } id="invite-brokerage-account-number"
                                       label="IBKR Account Number"
                                       value={inviteBrokerageAccount} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setInviteBrokerageAccount(event.target.value)
                            }}/>
                        )}
                        
                        <Button disabled={!inviteEmail || !inviteName || (!inviteBrokerageAccount && inviteAccountType !== "Retail") || !inviteAccountType} variant={"contained"}
                                color={"primary"} onClick={handleSendInvite}>Create Account & Invite</Button>

                        {newClient && (
                            <div >
                                <br/>
                                <Alert severity="info" className={styles.alert}>
                                    <AlertTitle>New account created, please provide the invite link to the new user. It will allow them to set their password for their account.</AlertTitle>

                                    <b>Email</b>: {newClient.email} <br/><b>User Id:</b> {newClient.id} <br/><b>Invite Url:</b><a href={newClient.inviteUrl}>Email this link</a></Alert>
                            </div>
                        )}
                    </FormGroup>
                </Grid>
                <Grid item md={6} sm={12}>
                    <h2>Pending subscription change requests</h2>
                    {accounts.map((a, i) => (
                        <SubscriptionCard key={i} account={a} buttonsDisabled={allButtonsDisabled}
                                          handleMarkAsComplete={handleMarkAsComplete} handleReject={handleReject}/>
                    ))}

                    {accounts.length === 0 && (
                        <p>There are no pending subscription change requests.</p>
                    )}
                </Grid>
            </Grid>
        </div>
    );
});

interface SubscriptionCardProp {
    account: IAccountModel;
    handleMarkAsComplete: (requestId: number) => void,
    handleReject: (requestId: number) => void,
    buttonsDisabled: boolean
}

const SubscriptionCard: FC<SubscriptionCardProp> = observer(({
                                                                 account,
                                                                 handleMarkAsComplete, handleReject,
                                                                 buttonsDisabled
                                                             }: SubscriptionCardProp) => {

    const subscriptions = (JSON.parse(account.requestedSubscription.subscriptionComposition) as SubscriptionCompositionType);

    const orderedSubscriptions = _.orderBy(subscriptions, 'Allocation', "desc");

    const cash = 100 - _.sum(orderedSubscriptions.map(s => s.Allocation));

    return (
        <Card variant={"outlined"} key={account.id} className={styles.card}>
            <CardContent>
                <h3>{account.name}</h3>
                <p>Email:<a href={"mailto:" + account.email}>{account.email}</a></p>
                <p>
                    Requested {moment(account.requestedSubscription.requestCreatedTimeStamp).fromNow()}
                </p>
                <Table>
                    <TableBody>
                        {orderedSubscriptions.map((p: any, i) => (
                            <TableRow key={i}>
                                <TableCell>{p.List}</TableCell>
                                <TableCell>{p.Allocation}%</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell><i>Cash</i></TableCell>
                            <TableCell>{cash}%</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </CardContent>
            <CardActions>
                <Button disabled={buttonsDisabled} className={styles.button} onClick={() => {
                    handleMarkAsComplete(account.requestedSubscription.id)
                }}>Mark as complete</Button>

                <Button disabled={buttonsDisabled} className={styles.buttonSecondary} onClick={() => {
                    handleReject(account.requestedSubscription.id)
                }}>Reject</Button>
            </CardActions>
        </Card>
    )
});
