import Dialog from '@material-ui/core/Dialog';
import React, {useEffect} from 'react';
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";


export const Disclaimer = () => {

    const [open, setOpen] = React.useState(false);
    const [hasRejected, setHasRejected] = React.useState(false);
    const descriptionElementRef = React.useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        sessionStorage.getItem('disclaimerAccepted') === 'true' ? setOpen(false) :
        setOpen(true);
    }, []);


    const handleClose = (event: any, reason: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    const handleAccept = () => {
        sessionStorage.setItem('disclaimerAccepted', 'true');
        setOpen(false);
    };

    const handleReject = () => {
        setHasRejected(!hasRejected);
    };

    useEffect(() => {
        if (open) {
            const {current: descriptionElement} = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Disclaimer</DialogTitle>
                <DialogContent dividers={true}>
                    {!hasRejected && (
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <Typography paragraph>
                                Any information provided by Suubee Portfolios or Suubee Pty Ltd ('Suubee') is general in
                                nature. The information provided on the Suubee website does not take into account your
                                particular objectives, financial situation, or needs. Suubee and its associates
                                may hold shares in the companies discussed or displayed.
                            </Typography>
                            <Typography paragraph>
                                Information provided for Suubee or any product owned by Suubee is for informational and
                                educational purposes only and should not be relied upon as the basis of an
                                investment decision. All trade information, including content published, is not
                                to be considered advice of any kind. You should obtain independent financial
                                advice in respect of any financial decision in relation to any stock discussed
                                on Suubee platforms.
                            </Typography>
                            <Typography paragraph>
                                Please be aware, it is possible that Suubee traders have a position in or have
                                previously bought
                                or sold shares in the companies for which information is presented. At times stocks
                                discussed
                                and posted on Suubee and any other associated Suubee platforms may be already owned by
                                any, or
                                all of the Suubee traders and their related entities. The information provided by
                                Suubee, any
                                communications, any related sites, webinars, emails or newsletters is intended for your
                                sole
                                consumption and use and is not be distributed to or shared with any 3rd party. In the
                                event you
                                distribute Suubee material without our consent, we reserve the right to terminate your
                                access to
                                Suubee and you will forfeit the value of any remaining access. All data is for your
                                personal use
                                only; it may not be posted on web sites, message boards, communicated verbally, or be
                                used in
                                products or services. All material in the Suubee website and its related websites and
                                pages are
                                protected under copyright laws of the Australia.
                            </Typography>
                            <Typography paragraph>
                                By using Suubee, you agree to the Terms of Service and Privacy Policy, and
                                acknowledge that Suubee Pty Ltd, its directors and employees are not liable to
                                you, or any other party, for your use of any information you receive through
                                Suubee.
                            </Typography>
                            <Typography paragraph>
                                The information included is from sources deemed to be reliable. Suubee, its
                                directors, employees, associates, and consultants do not represent, warrant or
                                guarantee expressly or impliedly, that the information contained in any medium
                                is complete or accurate.
                            </Typography>
                            <Typography paragraph>
                                Suubee also provides money management services for sophisticated (708) clients. Please
                                be aware that Suubee, its clients, employees or associates may have an interest
                                in the securities mentioned or displayed. Suubee may also earn brokerage,
                                commissions, fees and other benefits and advantages, whether pecuniary or not
                                and whether direct or indirect, in connection with the making of a
                                recommendation or a dealing by a client in these securities.
                            </Typography>
                            <Typography paragraph>
                                Clients for whom Suubee provides money management services should be aware that the
                                information or advice given does not take into account your particular
                                objectives, financial situation or needs. Before acting on the advice, you
                                should consider its appropriateness in relation to your investment objectives,
                                financial situation and needs.
                            </Typography>
                            <Typography paragraph>
                                This document is for private communication to Suubee users and is not intended for
                                public circulation. Information contained may be confidential and privileged.
                                If you have received this in error, please inform Suubee immediately and delete
                                the contents of the message.
                            </Typography>
                            <Typography paragraph>
                                SUUBEE PTY LTD: ABN 35 605 760 496
                                Authorised Representative No. 1262749 of
                                Sanlam Private Wealth which hold Australian Financial Services number 337927
                            </Typography>
                        </DialogContentText>
                    )}


                    {hasRejected && (
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <Typography paragraph>
                                Sorry, you must accept the terms and conditions to access this page.
                            </Typography>
                        </DialogContentText>
                    )}

                </DialogContent>
                <DialogActions>

                    <Button onClick={handleReject} color="secondary">
                        {!hasRejected ? "Reject" : "Back"}
                    </Button>
                    {!hasRejected && (
                        <Button onClick={handleAccept} color="primary">
                            Accept
                        </Button>
                    )}

                </DialogActions>
            </Dialog>
        </div>
    );
}