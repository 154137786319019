import {AuthenticationParameters, Configuration, UserAgentApplication} from 'msal';

import {
    AuthenticationAccount,
    AuthenticationError,
    AuthenticationProviderState,
    IAuthenticationProvider,
} from './AuthenticationTypes';

const scope =  'https://suubeegroupb2c.onmicrosoft.com/979ca4db-cd17-46af-996b-5b090359dd52/customer';

export class AuthenticationProvider implements IAuthenticationProvider {
    private readonly application: UserAgentApplication;

    constructor(config: Configuration, private readonly authParams: AuthenticationParameters) {
        this.application = new UserAgentApplication(config);
        this.application.handleRedirectCallback(AuthenticationProvider.authCallback);
    }

    public get initialState(): AuthenticationProviderState {
        return 'loading';
    }

    public get account(): AuthenticationAccount | null {
        return this.application.getAccount();
    }

    private static authCallback(error: any, message: any) {
        console.log('authCallback error: ' + JSON.stringify(error));
        console.log('authCallback message: ' + JSON.stringify(message));
        return;
    }

    public async signIn(): Promise<AuthenticationProviderState> {
        if (this.getIsSigningIn()) return 'signing-in';
        if (await this.getIsSignedIn()) return 'signed-in';
        this.signInRedirect();
        return 'redirecting';
    }

    public signOut(): void {
        sessionStorage.clear();
        this.application.logout();
    }

    public async getIsSignedIn(): Promise<boolean> {
        const local = !this.getIsSigningIn() && !!this.account;

        return local && (await this.canAcquireTokenSilent());
    }

    public async getApiAccessToken(): Promise<string> {
        if (await this.getIsSignedIn()) {
            const response = await this.application.acquireTokenSilent({scopes: [scope]});
            return response.accessToken;
        }
        throw new AuthenticationError();
    }

    // public async getGraphAccessToken(scopes?: string[]): Promise<string> {
    //   const accessTokenRequest: AuthenticationParameters = {
    //     scopes: scopes || ['user.read'],
    //   };
    //   if (await this.getIsSignedIn()) {
    //     const response = await this.application.acquireTokenSilent(accessTokenRequest);
    //     return response.accessToken;
    //   }
    //   throw new AuthenticationError();
    // }

    private signInRedirect() {
        this.application.loginRedirect(this.authParams);
    }

    private getIsSigningIn(): boolean {
        return (
            this.application.getLoginInProgress() ||
            this.application.urlContainsHash(window.location.hash)
        );
    }

    private async canAcquireTokenSilent(): Promise<boolean> {
        try {
            let request = {scopes: [scope]};
            await this.application.acquireTokenSilent(request).catch((error) => {
                console.log('canAcquireTokenSilent catch: ' + JSON.stringify(error));
                if (
                    error.errorCode === 'consent_required' ||
                    error.errorCode === 'interaction_required' ||
                    error.errorCode === 'login_required'
                ) {
                    this.application.acquireTokenRedirect(request);
                }
            });
            return true;
        } catch (e) {
            console.log('canAcquireTokenSilent catch-all: ' + e);
            return false;
        }
    }
}
