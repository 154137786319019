import {FC} from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {CountryComponent} from "./CountryComponent";

export const AddressComponent: FC = () =>{
    return <>
        <TextField id="AddressLine1" label="Address Line 1" required/>
        <TextField id="AddressLine2" label="Address Line 2"/>
        <TextField id="AddressLine2" label="Address Line 3"/>

        <TextField id="Suburb" label="Suburb"/>
        <TextField id="Postcode" label="Post Code"/>
        <TextField id="Postcode" label="Post Code"/>
        <FormControl sx={{minWidth: 120}} required>
            <InputLabel id="state-label">State</InputLabel>
            <Select
                labelId="state-label"
                id="state-select"
                value={''}
                label="State"
                onChange={() => {
                }}
            >
                <MenuItem value={'AUSTRALIAN_CAPITAL_TERRITORY'}>ACT</MenuItem>
                <MenuItem value={'NEW_SOUTH_WALES'}>NSW</MenuItem>
                <MenuItem value={'NORTHERN_TERRITORY'}>NT</MenuItem>
                <MenuItem value={'QUEENSLAND'}>QLD</MenuItem>
                <MenuItem value={'SOUTH_AUSTRALIA'}>SA</MenuItem>
                <MenuItem value={'TASMANIA'}>TAS</MenuItem>
                <MenuItem value={'VICTORIA'}>VIC</MenuItem>
                <MenuItem value={'WESTERN_AUSTRALIA'}>WA</MenuItem>
            </Select>
        </FormControl>
        <CountryComponent required={true} label="Country"/>
    </>
}