import {types} from 'mobx-state-tree';

import {flow, getEnv} from 'store/util';

export const SecurityModel = types.model('SecurityModel', {}).actions((self) => {
    const {auth} = getEnv(self);
    const getSignedInName = () => {
        const account = auth.account;
        return account && account.name;
    };
    const getAccountId = () => {
        const account = auth.account;
        return account && account.accountIdentifier;
    };
    const signIn = function* (): any {
        return yield auth.signIn();
    };
    const getApiAccessToken = function* (): any {
        return yield auth.getApiAccessToken();
    };
    return {
        signIn: flow(signIn),
        signOut: () => auth.signOut(),
        getIsSignedIn: () => auth.getIsSignedIn(),
        getApiAccessToken: flow(getApiAccessToken),
        getSignedInName,
        getAccountId,
    };
});
