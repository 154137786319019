import {flow as origFlow, getEnv as origGetEnv, getRoot as origGetRoot, IStateTreeNode, types,} from 'mobx-state-tree';
import ky from 'ky';
import {FlowReturn} from 'mobx-state-tree/dist/core/flow';
import {IRootStoreModel, IStoreEnvironment} from './RootStoreModel';
import {IAuthenticationProvider} from 'auth/AuthenticationTypes';

export function flow<T extends Promise<unknown>, R, Args extends unknown[]>(
  generator: (...args: Args) => Generator<T, R, T extends Promise<infer Y> ? Y : never>
): (...args: Args) => Promise<FlowReturn<R>> {
  return origFlow(generator);
}

export function getEnv(model: IStateTreeNode): IStoreEnvironment {
  return origGetEnv<IStoreEnvironment>(model);
}

export function getRoot(model: IStateTreeNode): IRootStoreModel {
  return origGetRoot<IRootStoreModel>(model);
}

export function getDefaultAjaxInstance(auth: IAuthenticationProvider): typeof ky {
  return ky.create({
    retry: { limit: 1 },
    prefixUrl: `${window.location.origin}/api`,
    hooks: {
      beforeRequest: [
        async (request) => {
          if (await auth.getIsSignedIn()) {
            request.headers.append('Authorization', `Bearer ${await auth.getApiAccessToken()}`);
          }
        },
      ],
    },
  });
}

export type ModelLoadingState = 'loading' | 'done' | 'error' | 'unauthorized';

export const ORDER_COMMISSION_PERCENTAGE = 1;

export const LoadingStateModel = types.optional(
  types.enumeration<ModelLoadingState>(['loading', 'done', 'error', 'unauthorized']),
  'loading'
);
