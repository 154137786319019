import * as React from 'react';
import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {useStore} from "../../hooks";
import {Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Chip} from "@mui/material";
import styles from "./Holdings.module.scss";
import {IHoldingModel} from "../../store/AccountModel";

export const Holdings = observer(() => {
    
    const {accountStore} = useStore();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [holdings, setHoldings] = useState<IHoldingModel[]>([]);
    
    useEffect(() => {
        accountStore.loadHoldings().then((accountHoldings)=> {
            setHoldings(accountHoldings);
        }).finally(() => {
            setIsLoading(false);
        });
    },[accountStore])
    
    return <div className={styles.container}> 
        {isLoading && (
            <Skeleton variant={"rectangular"} height={200}/>
        )}

        {!isLoading && holdings.length === 0 && (
            <p>You have no current holdings.</p>
        )}

        {!isLoading && holdings.length > 0 && (
            <Table size="small">
                <TableHead className={styles.rowHeader}>
                    <TableRow>
                        <TableCell>Ticker</TableCell>
                        <TableCell align="right">Position</TableCell>
                        <TableCell align="right">Average Cost</TableCell>
                        <TableCell align="right">Market Price</TableCell>
                        <TableCell align="right">Market Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(holdings).map((holding: IHoldingModel, key) => (
                        <TableRow key={key}>
                            <TableCell>{holding.securityCode}
                                {holding.lists.map((list, key) => (
                                    <Chip className={styles.chip} key={key} size="small" label={list} color="primary" /> 
                                ))}
                            </TableCell>
                            <TableCell align="right">{holding.totalHolding}</TableCell>
                            <TableCell
                                align="right">${(holding.averageCostInCents / 100).toFixed(2)}</TableCell>
                            <TableCell align="right">${(holding.marketPriceInCents / 100).toFixed(2)}</TableCell>
                            <TableCell align="right">${(holding.marketValueInCents / 100).toFixed(2)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )}
    </div>
});