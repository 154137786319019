import * as React from 'react';
import {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import {useStore} from "../../hooks";
import {IListModel} from "../../store/ListsModel";
import {FormControl, MenuItem, Select, SelectChangeEvent, Skeleton} from "@mui/material";
import styles from './Lists.module.scss';
import {Bar, BarChart, Cell, ReferenceLine, XAxis, YAxis} from "recharts";
import {observer} from 'mobx-react-lite';

export default observer(function Lists() {

    const {listsStore} = useStore();

    const [lists, setLists] = useState<IListModel[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        listsStore.loadLists().then(() => {
            setLists(listsStore.lists);
        }).then(() => {
            listsStore.selectList(listsStore.selectedList? listsStore.selectedList : listsStore.lists[0])
        }).finally(() => {
            setIsLoading(false);
        });
    }, [listsStore, setLists]);

    const handleListSelect = (event: SelectChangeEvent<string | number>) => {
        listsStore.selectList(lists.find(l => l.name === event.target.value)!);
    }

    return (
        <div className={styles.container}>
            <Title>Suubee Lists</Title>

            {isLoading ? (
                <>
                    <Skeleton variant={"rectangular"} height={60}/><br/>
                    <Skeleton variant={"rectangular"} height={160}/>
                </>
            ) : (
                <>
                    <FormControl fullWidth className={styles.sectorSelect}>
                        <Select
                            className={styles.select}
                            id="demo-simple-select"
                            value={listsStore.selectedList ? listsStore.selectedList.name : ''}
                            onChange={handleListSelect}
                        >
                            {lists.map((list, index) => (
                                <MenuItem id={'listmenuitem_' + index} className={styles.selectorSelect} key={index} value={list.name}>{list.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Table size="small">
                        <TableBody>
                            {lists.map((list, index) => (
                                <TableRow key={index} className={styles.row}>
                                    <TableCell><span
                                        className={list.name === listsStore.selectedList?.name ? styles.selectedList : undefined}>{list.name}</span></TableCell>
                                    <TableCell
                                        className={list.changeBasisPoints < 0 ? styles.negative : styles.positive}>{(list.changeBasisPoints / 100).toFixed(2)}%</TableCell>
                                    {index === 0 && (
                                        <TableCell className={styles.barCell} rowSpan={lists.length}>
                                            <BarChart data={lists} layout='vertical' width={100}
                                                      height={35 * lists.length}>
                                                <YAxis dataKey="name" type="category" hide/>
                                                <XAxis type="number" hide/>
                                                <ReferenceLine x={0} stroke="#000"/>
                                                <Bar dataKey="changeBasisPoints" fill="#8884d8">
                                                    {lists.map((datum, entry, index) => (
                                                        <Cell
                                                            key={`cell-${index}`}
                                                            fill={
                                                                datum.changeBasisPoints > 0
                                                                    ? 'green'
                                                                    : 'red'
                                                            }
                                                        />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </div>
    );
});